<div class="d-md-flex workflowTask -container">
  <div [ngClass]="isApiPage ? 'workflowTask -card-api -step' : 'workflowTask -card -step'">
    <div class="d-md-flex align-items-center">
      <icon *ngIf="!isApiPage"
        [icon]="iconProperties.iconPath"
        [ngClass]="iconProperties.className">
      </icon>
      <h5>
        <span *ngIf="!isApiPage">Step</span> {{ step }}
      </h5>
    </div>
  </div>

  <div class="workflowTask -card -content flex-grow-1">
    <h5>
      {{ title }}
    </h5>
    <p class="workflowTask -description" [innerHTML]="description">
      {{ description }}
    </p>
  </div>
 
  <div class="workflowTask -card -links" #contentWrapper>    
    <h5 *ngIf="contentWrapper.children.length >= 1">Helpful Links:</h5>
    <ng-content></ng-content>    
  </div>

</div>
