import {
  Component,
  OnInit,
  OnChanges,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { FileService } from '../../services/file.service';
import * as Prism from 'prismjs';
import 'prismjs/components/prism-markup'; // html
import 'prismjs/components/prism-css'; // css
import 'prismjs/components/prism-http'; // xml
import 'prismjs/components/prism-json'; // json
import 'prismjs/components/prism-javascript'; // js
import 'prismjs/components/prism-java'; // java
import 'prismjs/components/prism-kotlin'; // kotlin
import 'prismjs/components/prism-bash'; // bash, curl
import 'prismjs/components/prism-markup-templating'; // php
import 'prismjs/components/prism-clike'; // php
import 'prismjs/components/prism-php'; // php
import 'prismjs/components/prism-ruby'; // ruby
import 'prismjs/components/prism-csharp'; // dotnet
import 'prismjs/components/prism-swift'; // swift

import 'prismjs/plugins/line-numbers/prism-line-numbers.js';

const PROCESSED_CLASS = 'prettyprinted';

@Component({
  selector: 'codeblockitem',
  templateUrl: './code-block-item.component.html',
  styleUrls: ['./code-block-item.component.scss'],
})
export class CodeBlockItemComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() file: string;
  @Input() code: string;
  @Input() numbers = true;
  @Input() language = '';
  @Input() size = 'medium';
  @ViewChild('elem', { static: true }) elem: ElementRef;
  @ViewChild('content') content: ElementRef;

  sizeClass;

  public fileContents: any;
  public loading = false;

  constructor(private fileService: FileService, public ref: ChangeDetectorRef) {}

  ngOnInit() {
    this.sizeClass = this.size
      ? this.size === 's'
        ? 'small-size'
        : this.size === 'm'
        ? 'medium-size'
        : this.size === 'l'
        ? 'large-size'
        : this.size === 'xs'
        ? 'extra-small-size'
        : ''
      : '';
    if (this.file) {
      this.loading = true;
      if (this.language === '') {
        this.language = this.file.split('.').pop();
      }
      this.fileService.getFile(this.file).subscribe(
        (file) => {
          this.loading = false;
          this.fileContents = this.elem.nativeElement.textContent = this.code = file.trim();
          this.highlightSyntax(this.elem.nativeElement);
        },
        () => {
          this.loading = false;
        }
      );
    } else if (this.code) {
      this.fileContents = this.elem.nativeElement.textContent = this.code;
      this.highlightSyntax(this.elem.nativeElement);
    }
  }

  ngOnChanges() {
    const $elem = this.elem.nativeElement;

    if (this.code) {
      this.fileContents = this.elem.nativeElement.textContent = this.code;
      this.ref.detectChanges();
      this.highlightSyntax($elem);
    }
  }

  ngAfterViewInit() {
    const $elem = this.elem.nativeElement;

    if (!this.file && !this.code && ($elem.textContent || this.content.nativeElement.textContent)) {
      $elem.textContent = this.fileContents = this.content.nativeElement.textContent.trim();
      this.ref.detectChanges();
      this.highlightSyntax($elem);
    }
  }

  highlightSyntax($elem: Element) {
    if ($elem.textContent && $elem.textContent.trim().length && this.language) {
      Prism.highlightElement($elem, false, () => {
        $elem.classList.remove(PROCESSED_CLASS);
      });
    }
  }
}
