import { CognitoService } from '../../../services/cognito.service';
import { Component, OnInit, Input, HostBinding, SimpleChanges, OnChanges } from '@angular/core';
import { Router, UrlTree, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

//This class is extended by a few others
@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent implements OnInit, OnChanges {
  @HostBinding('class.hideButton') hideButton = false;
  @Input() to: string;
  @Input() externaloverride: boolean;
  @Input() disabled: boolean;
  @Input() className: string;
  @Input() disableRoute: boolean;
  @Input() authcheck = false;
  @Input() loginrequired = false;
  @Input() download = false;
  tree: UrlTree;
  url: string;
  template = 'external';

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public cognitoService: CognitoService,
    private location?: Location
  ) {}

  ngOnInit() {
    if (this.authcheck) {
      if (this.cognitoService.getUserInfo() !== null) {
        this.hideButton = true;
        return;
      }
    }

    if (this.disableRoute || this.to === undefined || this.to === '') {
      this.template = 'noRoute';
      return;
    }

    if (typeof this.externaloverride === 'undefined') {
      if (this.to && this.to.trim().search(/(http|https|mailto):/) !== 0) {
        this.template = 'internal';
        this.processInternalLink();
      } else {
        if (this.to && this.to.trim().search(/(mailto):/) === 0) {
          this.template = 'newTab';
        }
      }
    } else if (!this.externaloverride) {
      if (this.to && this.to.trim().search(/(http|https):/) !== 0) {
        this.template = 'internal';
        this.processInternalLink();
      } else {
        this.template = 'falseInternal';
      }
    } else {
      this.template = 'newTab';
    }
  }

  public isLinkActive(url: string): boolean {
    if (this.tree.fragment) {
      return location.href.indexOf(`${url}#${this.tree.fragment}`) !== -1;
    } else {
      url = url.endsWith('/') ? url.slice(0, -1) : url;
      return this.location.isCurrentPathEqualTo(url);
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.to) {
      this.processInternalLink();
    }
  }

  processInternalLink() {
    this.tree = this.router.parseUrl(this.to);
    // if (this.cognitoService.getUserInfo() === null && this.loginrequired) {
    //   this.tree = this.router.parseUrl('/account/login');
    //   const url = this.router.url + '?next=true';
    //   this.tree.queryParams['redirect_url'] = url;
    // }
    this.url = '';

    if (this.tree.root.children.primary) {
      const route = this.tree.root.children.primary.toString();
      this.url = `/${route}`;
    }

    if (this.to) {
      if (this.to.indexOf('#') === 0) {
        this.url = `/${this.route.snapshot.url.map((o) => o.path).join('/')}`;
      }
    }
  }

  handleClick() {
    const currentUrl = `/${this.route.snapshot.url.map((o) => o.path).join('/')}`;

    if (this.tree.fragment && currentUrl === this.url) {
      const element = document.getElementById(this.tree.fragment);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }
}
