import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { ProductSubscription } from '../../models/productSubscription.interface';
import { ProductSubscriptionService } from 'app/services/product-subscription.service';
import { CognitoService } from 'app/services/cognito.service';
import { DocPageNotificationService } from 'app/services/docpage-notification.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'releasenotes-subscription',
  templateUrl: './releasenotes-subscription.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ReleaseNotesSubscriptionComponent implements OnInit, OnDestroy {
  unsubscribeModal = false;
  infoModal = false;
  notification = { title: '', description: '', show: false, status: '' };
  productSubscription: ProductSubscription;
  subscription = new Subscription();
  subscriptionSubject = new Subject();

  constructor(
    private router: Router,
    private psService: ProductSubscriptionService,
    private cognitoService: CognitoService,
    private notificationService: DocPageNotificationService
  ) {}

  ngOnInit() {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      this.subscription.add(
        this.psService.getSubscription().subscribe((subscription: ProductSubscription) => {
          this.productSubscription = subscription;
          this.closeConfirmModal();
          this.notification.show = false;
          this.notificationService.sendNotification(this.notification);
        })
      );
    });

    this.subscription.add(
      this.psService.getSubscription().subscribe((subscription: ProductSubscription) => {
        this.productSubscription = subscription;
      })
    );
    this.subscription.add(this.subscriptionSubject.subscribe(() => this.handleSubscription()));
  }

  handleSubscription(): void {
    if (!this.cognitoService.getUserInfo()) {
      this.infoModal = true;
      return;
    }

    if (this.productSubscription.isProcessing) {
      return;
    }

    this.notification.show = false;
    this.notificationService.sendNotification(this.notification);

    if (!this.productSubscription.userSubscribed) {
      this.subscriptionAction();
    } else {
      this.unsubscribeModal = true;
    }
  }

  subscriptionAction() {
    this.closeConfirmModal();
    this.productSubscription.isProcessing = true;
    this.subscription.add(
      this.psService
        .updateSubscription({ ...this.productSubscription })
        .subscribe((updatedSubscription: ProductSubscription) => {
          this.productSubscription = updatedSubscription;
          this.notification = { ...this.notification, ...this.productSubscription.result, show: true };
          this.notificationService.sendNotification(this.notification);
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        })
    );
  }

  closeConfirmModal(): void {
    this.unsubscribeModal = false;
  }

  closeInfoModal(): void {
    this.infoModal = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
