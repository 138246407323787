import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigService } from 'app/services/config.service';
@Component({
  selector: 'api-left-menu',
  templateUrl: './api-left-menu.component.html',
})
export class APILeftMenuComponent implements OnInit, OnChanges {
  @Input() capabilityFilter: any;
  @Input() showCounts = true;
  @Input() menuActive;
  activeFilter = 'All';
  @Output() leftFilterChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public configService: ConfigService) {}

  ngOnChanges(): void {
    if (this.menuActive) {
      this.activeFilter = this.menuActive;
    }
  }

  ngOnInit() {}

  setActive(title: string) {
    this.activeFilter = title;
    this.leftFilterChange.emit(this.activeFilter);
  }
}
