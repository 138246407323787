import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { LocalStorageService } from './localStorage.service';

@Injectable()
export class ThemeIconService {
  themeInfo: Subject<any> = new Subject();

  constructor(private localStorageService: LocalStorageService) {}

  themeUpdatedData(message: string) {
    this.themeInfo.next(message);
  }

  switchThemeMode(theme: string) {
    theme = theme == 'light' ? 'dark' : 'light';
    this.localStorageService.setItem('theme', theme);
    document.documentElement.setAttribute('data-theme', theme);
    this.themeUpdatedData(theme);
    return theme;
  }

  getDefaultSetting() {
    let theme = this.localStorageService.getItem('theme');
    theme = theme != undefined || theme != null ? theme : 'light';
    document.documentElement.setAttribute('data-theme', theme);
    return theme;
  }
}
