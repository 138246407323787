<div class="redoc-parent">
  <app-breadcrumbs class="-api-spec-margin" *ngIf="!(metadata['secondary-nav'])"></app-breadcrumbs>
  <!-- <loader [loadingContent]="loadingContent"></loader> -->
  
  <!-- <iframe id="redoc-iframe" [hidden]=loadingContent style="width:100vw;height:100vh;border:0"></iframe> -->
  
  <div class="redoc-parent" [innerHtml]="redocSample"></div>
  <!-- <content-viewer class="wpdh-basic-template" *ngIf="body" [content]="redocSample" (docRendered)="handleDocRendered($event)"></content-viewer> -->
</div>

