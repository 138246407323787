<div class="-left-pane">
  <h3>
    Solutions
  </h3>
  <p>
    Worldpay offers a broad range of API products to suit every kind of business, no matter the size or location. Answer a few questions and let us help find what you are looking for.
  </p>
  <span class="-left-pane-link">
    <app-link [to]="'/solution-finder'" >Find your solution</app-link>
  </span> 
</div>
<div class="-right-pane">
  <header-tabs *ngIf="type === 'tabs'"></header-tabs>
  <div *ngIf="type === 'no-tabs'" class="-no-tab-container">
    <div *ngFor="let menu of  menuContent.children">
      <h4>{{menu.title}}</h4>
      <p>{{menu.description}}</p>
      <div *ngFor="let link of menu.columnOne">
        <app-link [to]="link.path">{{ link.title }}</app-link>
      </div>
    </div>
  </div>
</div>