import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class PageService {
  getApiKeyConfirmEvent = new EventEmitter<string>();
  constructor(private apiService: ApiService) {}

  getPage(route: string, token = '') {
    return this.apiService.get('/Nodes', { route }, token);
  }
}
