<ul *ngFor="let item of navItems; index as itemIndex" class="nav-vertical -menu">
  <li class="nav-vertical -menu -item">
    <div class="nav-vertical -menu -item -container d-flex justify-content-between"
      [ngClass]="{ '-active': item.active }">

      <app-link 
        class="nav-vertical -menu -item -link -fontMedium"
        (click)="toggleChild('p', itemIndex)"
        [ngClass]="{ '-active': item.active }" 
        [to]="item.path ? item.path : undefined"
        [disableRoute]="item.path ? false : true"
        [externaloverride]="item.newTab">
        {{ item.title }}
      </app-link>

      <button-icon 
        *ngIf="item.children" 
        iconcolor="-colorSupportingWhite"
        iconattributes="-sm15"
        iconhovercolor="-colorPrimaryGreen"
        (click)="toggleChild('p', itemIndex)"
        [icon]="_closedStates['p'][itemIndex]['icon']">
      </button-icon>

      <app-link
        *ngIf="item.newTab"
        [to]="item.path ? item.path : undefined"
        [externaloverride]="item.newTab">
        <button-icon 
          iconcolor="-colorSupportingWhite"
          iconattributes="-sm15"
          iconhovercolor="-colorPrimaryGreen"
          (click)="toggleChild('p', itemIndex)"
          icon="external-link">
        </button-icon>
      </app-link>
    </div>

    <ul *ngIf="item.children" [@_closed]="_closedStates['p'][itemIndex]['state']"
      class="nav-vertical -menuChild">
      <ng-container *ngFor="let child of item.children; index as childIndex">
        <li class="nav-vertical -menuChild -item">
          <div class="nav-vertical -menuChild -item -container d-flex align-items-start"
            [ngClass]="{ '-hasIcon': child.children }">
            <button-icon 
              *ngIf="child.children"
              iconcolor="-colorSupportingWhite"
              iconattributes="-sm15"
              iconhovercolor="-colorPrimaryGreen"
              (click)="toggleChild('c', childIndex)"
              [icon]="_closedStates['c'][childIndex]['icon']">
            </button-icon>

            <app-link class="nav-vertical -menuChild -item -link -fontXSmall"
              [ngClass]="{ '-active': child.active }" [to]="child.path" [disableRoute]="!child.path">
              {{ child.title }}
            </app-link>
          </div>
          <ul class="nav-vertical -menuGrandchild"
            *ngIf="child.children" 
            [@_closed]="_closedStates['c'][childIndex]['state']">
            <ng-container *ngFor="let grandchild of child.children">
              <li class="nav-vertical -menuGrandchild -item">
                <app-link class="nav-vertical -menuGrandchild -item -link -fontXSmall"
                  [ngClass]="{ '-active': grandchild.active }" [to]="grandchild.path">
                  {{ grandchild.title }}
                </app-link>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
    </ul>
  </li>
</ul>
