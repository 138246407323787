import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class SearchService {
  showSearch: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(protected apiService: ApiService) {}

  getSearch(query: string): Observable<any> {
    return this.apiService.get('/Search', query);
  }

  getFacetSearch(query: string): Observable<any> {
    return this.apiService.get('/Facet', query);
  }

  toggleSearch() {
    this.showSearch.next(!this.showSearch.value);
  }
}
