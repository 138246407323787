import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import {distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class WaypointService {
  public scrollObservable: Observable<number>;

  constructor() {}

  getElementObservable(element, buffer = 0) {
    let waypoint = 0;
    const menuHeight = 75; // Accross mobile and desktop

    try {
      if (typeof window.pageYOffset !== 'undefined') {
        waypoint = element.getBoundingClientRect().top - buffer + window.pageYOffset - menuHeight;
      }
    } catch (e) {
      // Not supported in domino
    }

    return this.getScrollObservable().pipe(
      map(pos => waypoint - pos < 0)
    ).pipe(distinctUntilChanged());
  }

  getScrollObservable() {
    if (!this.scrollObservable) {

      this.scrollObservable = fromEvent(document, 'scroll')
        .pipe(map(() => this.getScroll()))
        .pipe(distinctUntilChanged());
    }

    return this.scrollObservable;
  }

  private getScroll = function () {
    let pos = 0;

    if (typeof window.pageYOffset !== 'undefined') {
      pos = window.pageYOffset;
    }

    return pos;
  }
}
