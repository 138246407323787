import { Component, Input } from '@angular/core';
import { CardComponent } from '../card/card.component';

@Component({
  selector: 'cardAPI',
  templateUrl: './card-api.component.html',
  styleUrls: ['./card-api.component.scss'],
})
export class CardAPIComponent extends CardComponent {
  @Input() to: string;
  @Input() linktitle: string;
}
