<div class="notification -container" [ngClass]="{'-displayNone' : hideNotification}">

  <div class="notification -title" [ngClass]="{'-noRightBorder' : noTitle}">
    {{ title }}
  </div>
  <div class="notification -description -centerDescription"
    [ngClass]="{'-centerDescription' : centerDescription}">
    <p>
      <ng-content></ng-content>
    </p>
  </div>
  <div *ngIf="showDismissBtn" class="notification -dismiss">
    <button-icon
      title="Dismiss" 
      iconcolor="-colorSupportingWhite"
      iconattributes="-xsm"
      iconhovercolor="-colorPrimaryGreen"
      (click)="dismiss()"
      icon="close">
    </button-icon>
  </div>
</div>