<div 
  [class]="size === 'default' ? 'dropdown -parent' : 'dropdown -parent -lg'"
  (click)="toggleState()" *ngIf="!type" >
  <div 
    class="dropdown -header" 
    *ngIf="buttonTitle" 
    [class.dropdown-active]="active">
    <div class="dropdown -button">
      {{ buttonTitle }}
    </div>
    <icon 
      class="-colorSupportingWhite" 
      [class.dropdown-rotate-icon]="active" 
      icon="dropdown">
    </icon>
  </div>
  <div class="dropdown -content" [@dropdownState]="state">
    <div
      class="dropdown -content-option" 
      *ngFor="let content of dropdownContent"
      (click)="optionClicked(content)">
      {{ content }}
    </div>
  </div>
</div>
<div class="dropdown -parent -multiselect" *ngIf="type === 'sort'" clickOutside (clickOutside)="clickedOutside()">
  <div class="-header-sort" *ngIf="buttonTitle" (click)="toggleState()">
    <div class="-button">{{ buttonTitle }}{{ sortTypeSelected }}</div>    
    <div>
      <icon class="-colorSupportingWhite -xsm" [class.dropdown-rotate-icon]="active" icon="dropdown"></icon>
    </div> 
  </div>
  <div class="-content-sort" [@dropdownState]="state">
    <div class="content-padding" >
      <div class="-content-option-sort" *ngFor="let content of dropdownContent">
        <label class="-group-element" (click)="sortOptionSelected(content, $event)">
            {{content}}   
        </label>
      </div>
    </div>
  </div>
</div>