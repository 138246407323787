<div class="mastheadcta -notification">
  <notification 
    [class]="notification.status === 'failure' ? '-colorAccentPink' : '-colorAccentLightBlue'"
    [title]="notification.title" 
    [showDismissBtn]="true" 
    *ngIf="notification && notification.show">
    {{ notification.description }}
  </notification>
</div>

<div *ngIf="!hidebreadcrumbs">
  <div class="row">
    <div [ngClass]="offset !== null ? 'col-md-4 col-md-offset-' + offset : ''">
      <app-breadcrumbs></app-breadcrumbs>
    </div>
  </div>
</div>

<div [ngClass]="contentClass" class="mastheadcta -wrapper">
  <ng-content></ng-content>
  <releasenotes-subscription></releasenotes-subscription>
</div>