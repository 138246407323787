import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Injectable()
export class FormService {
  isProcessing: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private apiService: ApiService) {}

  postForm(payload) {
    return this.apiService.post('/Form', payload);
  }

  getForm(id: string) {
    return this.apiService.get('/Form', { id });
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  noInvalidNameValidator(control: UntypedFormControl) {
    const isValid = !/[<>&"'/._@\-\\+]/.test(control.value);
    return isValid ? null : { invalidname: true };
  }
}
