import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations: [
    trigger('closed', [
      state(
        'false',
        style({
          height: AUTO_STYLE,
        })
      ),
      state(
        'true',
        style({
          height: 0,
        })
      ),
      transition('false <=> true', [animate('125ms')]),
    ]),
  ],
})
export class AccordionComponent {
  @Input() title: string;
  @Input() date: string;
  @Input() hiddenContent: string;

  closed = true;
  icon: 'plus' | 'subtract' = 'plus';

  changeExpandState() {
    this.closed = !this.closed;

    if (this.closed) {
      this.icon = 'plus';
    } else {
      this.icon = 'subtract';
    }
  }

}
