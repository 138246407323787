<div
  [formGroup]="form"
  class="form -group"
  [class.is-invalid]="!isValid && (isDirty || isTouched)"
  [class.is-valid]="isValid && (isDirty || isTouched)">

  <span class="-fontMedium">
    {{ question.label }}
    <span *ngIf="!question.required && !hideOptional"> (Optional)</span>
  </span>
  <span *ngIf="question.help" class="form -help">{{ question.help }}</span>

  <ng-container [ngSwitch]="question.type">
    <div *ngSwitchCase="'radio'" class="form -group-wrapper">
      <div *ngFor="let option of question.options" class="form -group-element-wrapper noSelector">
        <label class="form -group-element noSelector">
          <input type="radio" [name]="question.key" [formControlName]="question.key" [value]="option.value" />
          <span class="form -group-element-radio"></span>
          <span class="form -group-element-label -fontSmall">{{ option.label }}</span>
        </label>
      </div>
    </div>

    <div *ngSwitchCase="'checkbox'" [formGroupName]="question.key" class="form -group-wrapper">
      <div *ngFor="let option of question.options" class="form -group-element-wrapper noSelector">
        <label class="form -group-element noSelector">
          <input type="checkbox" [formControlName]="option.value"/>
          <span class="form -group-element-checkbox"></span>
          <span class="form -group-element-label -fontSmall" *ngIf="!hideCheckboxLabel">{{ option.value }}</span>
        </label>
      </div>
    </div>
  </ng-container>

  <span class="form -error -fontSmall" *ngIf="!isValid && (isDirty || isTouched)">
    <span *ngIf="errors.required">
      {{ question.label }} is required
    </span>
    <span *ngIf="errors.multiCheckboxRequired">
      {{ question.label }} is required
    </span>
  </span>
</div>

