import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WpFormGroupComponent } from 'app/components/_form/wp-form-group/wp-form-group.component';
import { WpFormQuestionComponent } from 'app/components/_form/wp-form-question/wp-form-question.component';
import { WpFormComponent } from 'app/components/_form/wp-form/wp-form.component';
import { FormService } from 'app/services/form.service';
import { RestfulCheckboxComponent } from 'app/components/_form/restful-checkbox/restful-checkbox.component';
import { RestfulCheckboxCollectionComponent } from 'app/components/_form/restful-checkbox-collection/restful-checkbox-collection.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    WpFormComponent,
    WpFormQuestionComponent,
    WpFormGroupComponent,
    RestfulCheckboxComponent,
    RestfulCheckboxCollectionComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule],
  providers: [FormService],
  exports: [
    WpFormComponent,
    WpFormQuestionComponent,
    WpFormGroupComponent,
    RestfulCheckboxComponent,
    RestfulCheckboxCollectionComponent,
  ],
})
export class FormModule {}
