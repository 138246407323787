<ul class="wpdh-pager">
  <li><button class="wpdh-pager__button wpdh-pager__button--previous"
    (click)="previousPage()">Previous</button></li>
  <li class="wpdh-pager__excess-icon"
    [class.is-active]="lowerLimit > 0">...</li>
  <li *ngFor="let page of pager">
    <button class="wpdh-pager__button"
      *ngIf="pager.indexOf(page) >= lowerLimit && pager.indexOf(page) < upperLimit"
      [class.is-active]="page === activePage"
      (click)="updatePage(page)">{{ page.label }}</button>
  </li>
  <li class="wpdh-pager__excess-icon"
    [class.is-active]="upperLimit < pager.length">...</li>
  <li><button class="wpdh-pager__button wpdh-pager__button--next"
    (click)="nextPage()">Next</button></li>
</ul>
