<ul class="pager">
  <li>
    <button-icon
      style="transform:scale(-1,1)"
      icon="doublearrow"
      (click)="firstPage()"
      *ngIf="activePage > 0">
    </button-icon>
  </li>
  <li>
    <button-icon
      style="transform:scale(-1,1)"
      icon="singlearrow-left"
      (click)="previousPage()"
      *ngIf="activePage > 0">
    </button-icon>
  </li>
  <li class="pager -excess-icon"
    [class.is-active]="lowerLimit > 0">...</li>
  <li *ngFor="let page of pager">
    <button class="pager -button"
      *ngIf="page.index >= lowerLimit && page.index < upperLimit"
      [class.is-active]="page.index === activePage"
      (click)="updatePage(page.index)">{{ page.label }}</button>
  </li>
  <li class="pager -excess-icon"
    [class.is-active]="upperLimit < pager.length">...</li>
  <li>
    <button-icon
      style="transform:scale(-1,1)"
      icon="singlearrow-right"
      (click)="nextPage()"
      *ngIf="(activePage + 1) < pager.length">
    </button-icon>
  </li>
  <li>
    <button-icon
      style="transform:scale(-1,1)"
      icon="doublearrow-right"
      (click)="lastPage()"
      *ngIf="(activePage + 1) < pager.length">
    </button-icon>
  </li>
</ul>
