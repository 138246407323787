import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'rest-verb-box',
  templateUrl: './rest-verb-box.component.html',
  styleUrls: ['./rest-verb-box.component.scss'],
})
export class RestVerbBoxComponent {
  @Input() verb = '';
  filterColors = {
    POST: '-restPostColor',
    GET: '-restGetColor',
    DELETE: '-restDeleteColor',
    PATCH: '-restPatchColor',
    PUT: '-restPutColor',
  };

  @HostBinding('className') get backgroundClass() {
    return this.filterColors[this.verb];
  }

  constructor() {}
}
