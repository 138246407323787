<div class="small-icon-tile -tile-container">  
    <div class="small-icon-tile -tile-interior -colorShadePrimaryBlack">
      <div class="small-icon-tile -icon-background">
        <icon [icon]="icon"></icon>
      </div>
      
      <div class="small-icon-tile -tile-details">
        <h3>{{title}}</h3>
        <ng-content></ng-content>
      </div>
    </div>
</div>