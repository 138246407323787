import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { LoaderService } from 'app/services/loader.service';

@Component({
  selector: 'app-four-oh-three',
  templateUrl: './four-oh-three.component.html',
  styleUrls: ['./four-oh-three.component.scss'],
})
export class FourOhThreeComponent implements OnInit {
  constructor(
    public globals: Globals,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.loaderService.loadingContent.emit(false);
  }
}
