import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ModalMatchService {
  private modalBoxId = new BehaviorSubject<any>('');
  listenerToListenForModalBoxId = this.modalBoxId.asObservable();
  constructor() { }

  openBoxById(boxId: string) {
    this.modalBoxId.next(boxId)
  }
  stopsub() {
    this.modalBoxId.next('')
  }

}
