import { MetaService } from './meta.service';
import { LoaderService } from './loader.service';
import { CognitoService } from './cognito.service';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { DashboardService } from './dashboard.service';

@Injectable()
export class TokenService {
  dashboardService: DashboardService;

  constructor(
    private apiService: ApiService,
    private cognitoService: CognitoService,
    private router: Router,
    private loaderService: LoaderService,
    private metaService: MetaService
  ) {}

  createToken(apiList?: any) {
    if (typeof apiList !== 'undefined') {
      return this.createTokenHelper({ products: [apiList] });
    } else {
      return this.createTokenHelper({ products: [this.metaService.metadata.value.api] });
    }
  }

  createProdToken(apiList?: any) {
    return this.createTokenHelper({ products: [apiList], certified: true });
  }

  // Review the account check here with the router guards
  createTokenHelper(requestBody: any) {
    this.loaderService.loadingContent.emit(true);
    if (!this.cognitoService.getUserInfo()) {
      this.router.navigate(['/account/login']);
    } else {
      return this.apiService.post('/Token', requestBody);
    }
  }

  getAllTokens() {
    if (!this.cognitoService.getUserInfo()) {
      this.router.navigate(['account/login']);
    } else {
      return this.apiService.get('/Token');
    }
  }

  getTokenAutoKey() {
    return this.apiService.get('/Token'); 
  }

  getAllProdTokens() {
    if (!this.cognitoService.getUserInfo()) {
      this.router.navigate(['account/login']);
    } else {
      return this.apiService.get('/Token', null, null, { certified: true });
    }
  }
}
