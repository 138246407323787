import { trigger, animate, transition, state, style } from '@angular/animations';

const fadeOutDuration = 100;
const fadeInDuration = 200;

export const fade = trigger('fade', [
  state('complete', style({
    opacity: 1,
    'pointer-events': 'unset',
    cursor: 'unset'
  })),
  state('updating', style({
    opacity: 0,
    'pointer-events': 'none',
    cursor: 'default'
  })),
  transition('complete => updating', [
    animate(fadeOutDuration)
  ]),
  transition('updating => complete', [
    animate(fadeInDuration)
  ])
]);