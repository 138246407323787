<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <div class="wpdh-404">
        <!-- <banner image="/assets/website-illustrations/new-icons/Glossary.png"
          [reverse]="false">
        </banner> -->
        <h1 class="wpdh-404__title">404</h1>
        <h2>Page not found</h2>
        <p>Sorry! We couldn't find this page.</p>
        <wpbutton to="/">Go to homepage</wpbutton>
      </div>
    </div>
  </div>
</div>