import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable()
export class ConfigService {

  constructor(private apiService: ApiService) { }

  getConfig(id) {
    return this.apiService.get('/Configuration', {id: id});
  }

}
