<div class="dashboard-border">
  <secondary-masthead>
    <div class="-fontDisplayXSmallHeading">API Monitoring</div>
    <p class="-details">View your API stats for all the keys you’ve requested. You can request new API keys from within an API product page. Once you start making requests with the key, your analytics graph will begin to populate.</p>      
  </secondary-masthead>
</div>

<div class="dashboard-monitoring -api-select" *ngIf="apisFromLicenses.length !== 0">
  <p>
    Select API to Monitor
  </p>
  <dropdown-convergence
    size="lg"
    [buttonTitle]="dropdownOptions[0]" 
    [dropdownContent]="dropdownOptions"
    (optionSelected)="selectedAPIDropdown($event)">
  </dropdown-convergence>
</div>

<div class="dashboard-monitoring -graphs">
  <ng-container *ngIf="apisFromLicenses.length === 0">
    <p>
      After obtaining and using an API Key from our self service products, you will find useful metrics here.
    </p>
  </ng-container>

  <div *ngIf="apisFromLicenses.length !== 0">
    <tab-container tabContentPadding="-no-side-padding" spacebetween="true" (selectedTableTitle)="setSelectedMetric($event)">
      <tab-item title="Traffic Summary">
        <ng-container *ngIf="selectedAPI && selectedMetric === 'Traffic Summary'">
          <app-chart-parent
            metricType="trafficSum"
            chartHeightPx="350"
            [appName]="selectedAPI.appName"
            [apiName]="selectedAPI.apiName">
          </app-chart-parent>
        </ng-container>
      </tab-item>
      <tab-item title="Errors">
        <ng-container *ngIf="selectedAPI && selectedMetric === 'Errors'">
          <app-chart-parent
            metricType="errorSum"
            chartHeightPx="350"
            [appName]="selectedAPI.appName"
            [apiName]="selectedAPI.apiName">
          </app-chart-parent>
        </ng-container>
      </tab-item>
      <tab-item title="Avg. Request Processing Latency (ms)">
        <ng-container *ngIf="selectedAPI && selectedMetric === 'Avg. Request Processing Latency (ms)'">
          <app-chart-parent 
            metricType="avgRequestProcessingLatency"
            chartHeight="350"
            [appName]="selectedAPI.appName"
            [apiName]="selectedAPI.apiName">
          </app-chart-parent>
        </ng-container>
      </tab-item>
      <tab-item title="Avg. Total Response Time (ms)">
        <ng-container *ngIf="selectedAPI && selectedMetric === 'Avg. Total Response Time (ms)'">
          <app-chart-parent 
            metricType="avgTotalResponseTime"
            chartHeight="350"
            [appName]="selectedAPI.appName"
            [apiName]="selectedAPI.apiName">
          </app-chart-parent>
        </ng-container>
      </tab-item>
    </tab-container>
  </div>
</div>

<div class="dashboard-monitoring -footer-buttons">
  <wpbutton class="-outline" (click)="switchAPIKeyModal()">
    Request new API key
  </wpbutton>
  <link-icon *ngIf="selectedAPI" [to]="selectedAPI.apiSpec.toString()">
    View API Specification
  </link-icon>
</div>

<modal 
  [(visible)]="showAPIKeyModal" 
  (closeModal)="switchAPIKeyModal()" 
  [secondaryModal]="true" 
  title="Request new API Key">
  <div class="monitoring-modal -body">
    You can request new API keys within an API product page.
    <p>
      Explore our APIs.
    </p>
    <wpbutton class="" to="/apis">
      Explore our APIs
    </wpbutton>
    <wpbutton class="-gray" (click)="switchAPIKeyModal()">
      Close
    </wpbutton>
  </div>
</modal>

<!-- <div class="dashboard -analytics">
  <div class="col-sm-12 col-md-10 col-md-offset-1">
    <div *ngIf="apiKeys.length === 0">
      <p>
        After obtaining and using an API Key from our self service products, you will find useful metrics here.
      </p>
    </div>
    <div *ngIf="apisFromLicenses.length !== 0">
      <tab-container tabContentPadding="-no-side-padding" (selectedTableTitle)="setSelectedAPI($event)">
        <ng-container *ngFor="let appAPI of apisFromLicenses">
          <ng-container *ngFor="let apiName of appAPI.apis; index as index">
            <tab-item [id]="appAPI.apiTitles ? appAPI.apiTitles[index].toString() : apiName.toString()">
              <ng-container *ngIf="appAPI.apiTitles ? selectedAPI === appAPI.apiTitles[index] : selectedAPI === apiName">
                <tab-container tabContentPadding="-no-side-padding" spacebetween="true" (selectedTableTitle)="setSelectedMetric($event)">
                  <tab-item title="Traffic Summary">
                    <ng-container *ngIf="selectedMetric === 'Traffic Summary'">
                      <app-chart-parent
                        metricType="trafficSum"
                        chartHeightPx="400"
                        [appName]="appAPI.appName"
                        [useSampleData]="true"
                        [apiName]="apiName">
                      </app-chart-parent>
                    </ng-container>
                  </tab-item>
                  <tab-item title="Errors">
                    <ng-container *ngIf="selectedMetric === 'Errors'">
                      <app-chart-parent
                        metricType="errorSum"
                        chartHeightPx="400"
                        [appName]="appAPI.appName"
                        [apiName]="apiName">
                      </app-chart-parent>
                    </ng-container>
                  </tab-item>
                  <tab-item title="Avg. Request Processing Latency (ms)">
                    <ng-container *ngIf="selectedMetric === 'Avg. Request Processing Latency (ms)'">
                      <app-chart-parent 
                        metricType="avgRequestProcessingLatency"
                        chartHeight="400"
                        [appName]="appAPI.appName"
                        [apiName]="apiName">
                      </app-chart-parent>
                    </ng-container>
                  </tab-item>
                  <tab-item title="Avg. Total Response Time (ms)">
                    <ng-container *ngIf="selectedMetric === 'Avg. Total Response Time (ms)'">
                      <app-chart-parent 
                        metricType="avgTotalResponseTime"
                        chartHeight="400"
                        [appName]="appAPI.appName"
                        [apiName]="apiName">
                      </app-chart-parent>
                    </ng-container>
                  </tab-item>
                </tab-container>
              </ng-container>
            </tab-item>
          </ng-container>
        </ng-container>
      </tab-container>
    </div>
  </div>
</div> -->