import { Component, ElementRef, ViewChild, AfterViewInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'sliding-button',
  templateUrl: './sliding-button.component.html',
})
export class SlidingButtonComponent implements AfterViewInit, OnDestroy {
  @Input() options = [];
  @Output() selectedOption = new EventEmitter();

  @ViewChild('slider') slider: ElementRef<HTMLElement>;
  @ViewChild('slidingOptions') slidingOptions: ElementRef<HTMLElement>;
  lastActiveIndex = 0;

  optionItems: NodeListOf<HTMLAnchorElement>;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.options && this.options.length > 0) {
      this.optionItems = this.slidingOptions.nativeElement.querySelectorAll('a');

      this.optionItems.forEach((item, currentActiveIndex) => {
        item.addEventListener('click', (e: any) => {
          this.optionItems[this.lastActiveIndex].parentElement.classList.remove('-active');

          if (this.lastActiveIndex > 0) {
            this.optionItems[this.lastActiveIndex - 1].parentElement.classList.remove('-pre-active');
          }
          this.slider.nativeElement.style.cssText =
            'z-index: 1; left: ' +
            e.target.parentElement.offsetLeft +
            'px; width: ' +
            e.target.parentElement.offsetWidth +
            'px;';
          e.target.parentElement.classList.add('-active');

          if (currentActiveIndex > 0) {
            this.optionItems[currentActiveIndex - 1].parentElement.classList.add('-pre-active');
          }
          this.lastActiveIndex = currentActiveIndex;
          this.selectedOption.emit(currentActiveIndex);
        });
      });

      this.optionItems[0].click();
    }
  }

  ngOnDestroy(): void {
    this.optionItems.forEach((item) => {
      item.removeEventListener('click', () => {});
    });
  }
}
