import { Injectable } from '@angular/core';
import { WorkflowTaskStatus } from 'app/models/workflowTaskStatus.interface';

@Injectable()
export class WorkflowService {
  constructor() {}

  updateTaskIcon(taskStatus: WorkflowTaskStatus, linkCount: number | null, section: string, stepIndex: number | null) {
    taskStatus[section].status = 'active';

    // If a section does not have any subtasks, the stepIndex will be null
    if (stepIndex !== null) {
      if (taskStatus[section].subTasks) {
        taskStatus[section].subTasks[stepIndex].status = 'active';
        taskStatus[section].subTasks[stepIndex].clickedLinks += 1;
      }

      if (linkCount <= taskStatus[section].subTasks[stepIndex].clickedLinks) {
        taskStatus[section].subTasks[stepIndex].status = 'checkmark';

        if (taskStatus[section].subTasks[stepIndex].allLinksClicked === false) {
          taskStatus[section].subTasks[stepIndex].allLinksClicked = true;
          taskStatus[section].complete += 1;
        }
      }

      if (
        taskStatus[section].subTasks.filter((x: any) => x.status === 'checkmark').length >=
        taskStatus[section].subTasks.length
      ) {
        taskStatus[section].status = 'checkmark';
      }
    } else {
      taskStatus[section].status = 'checkmark';
    }
  }
}
