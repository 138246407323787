import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function emailDomainValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      ['@wearehackerone.', '@hackerone.', '@bugcrowd.', '@bbitj.', '@mailinator.'].some((v) =>
        control.value.includes(v)
      )
    ) {
      return { noDomainallowed: true };
    }
    return null;
  };
}
