<div class="code-block -parent"
  [class.code-block--light]="colour === 'light'"
  [class.code-block--dark]="colour === 'dark'">
  <ul *ngIf="tabs.length > 1"
    class="code-block -nav"
    [class.is-fixed]="fixed && !mobile"
    #nav>
    <ng-container *ngFor="let tab of tabs">
      <li *ngIf="tab">
        <button type="button"
          (click)="onSelect(tab.titleCode)"
          [class.is-active]="tab.title === selectedLanguage">{{ tab.title }}</button>
      </li>
    </ng-container>
  </ul>
  <div class="code-block -results">
    <button class="code-block -copy-button" (click)="onCopyClick()">Copy</button>
    <div class="code-block -success" [class.is-animating]="successAnimating">
      Copied!
    </div>
    <div #results>
      <ng-content></ng-content>
    </div>
  </div>
</div>
