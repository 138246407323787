import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppBrowserModule } from './app/app.module';
import { environment } from './environments/environment';

const redirect = () => {
  const currentUrl = window.location.href;
  const redirectUrl = currentUrl.replace(environment.oldAppURL, environment.apiHost);
  if (window.location.href.includes(environment.oldAppURL)) {
    window.location.replace(redirectUrl);
  }
};

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      if (registration.active.scriptURL === `${environment.oldAppURL}/ngsw-worker.js`) {
        registration.unregister().then(() => {
          redirect();
        });
      }
    }
  });
}
redirect();

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppBrowserModule);
