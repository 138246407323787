<div class="code-block -contents" data-contents>
  <!-- <div class="wpdh-loader"
    *ngIf="loading">
    <div class="wpdh-global-loader">
      <div class="wpdh-global-loader__circle"></div>
      <div class="wpdh-global-loader__line--mask">
        <div class="wpdh-global-loader__line"></div>
      </div>
      <img src="/assets/images/icon.svg"/>
    </div>
  </div> -->
  <pre class="code-block -result prettyprint {{numbers ? 'line-numbers' : ''}}" [ngClass]="sizeClass ? sizeClass : ''"><code #elem data-code [ngClass]="language ? 'language-' + language : ''">{{ fileContents }}</code></pre>
</div>
<div class="hidden" #content data-raw *ngIf="code">{{ code }}</div>
<div class="hidden" #content data-raw *ngIf="!code">
  <ng-content></ng-content>
</div>