<span *ngIf="!typeFilter"
  (click)="toggleCheckedStatus()"
  class="-pill -fontMedium"
  [ngClass]="{ '-canclick': canclick }"
>
<ng-container *ngTemplateOutlet="content"></ng-container>

  <icon
    [icon]="'checkmark'"
    class="-colorShadePrimaryBlackS20 -sm"
    [ngClass]="{ hidden: !checked }"
  ></icon>
  <input type="checkbox" [checked]="checked" class="hidden" /> 
</span>

<span *ngIf="typeFilter" class="-filter-pill -fontMedium">
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <span (click)="deselectFilter($event)">
    <icon [icon]="'close-dark'" class="-colorShadePrimaryBlackS20 -xsm"></icon>
  </span> 
</span>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
