import { Component, Input, OnInit } from '@angular/core';
import { MenuService } from 'app/services/menu.service';

@Component({
  selector: 'header-tabs',
  templateUrl: './header-tabs.component.html',
})
export class HeaderTabsComponent implements OnInit {
  @Input() type: string;
  private tabElementsId = 'global-main-menu';
  tabElements = [];

  constructor(public menuService: MenuService) {}

  ngOnInit() {
    this.menuService.getMenu(this.tabElementsId).subscribe((menu) => {
      if (menu.Items && menu.Items.length > 0) {
        this.tabElements = menu.Items[0].items;
      }
    });
  }
}
