import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
})
export class MainNavComponent implements OnInit {
  private menuId = 'global-main-menu';

  waypointObservable: Observable<boolean>;
  fixed = false;
  mobile: boolean;
  menu: any;
  @Output() menuReady = new EventEmitter();

  constructor(
    private menuService: MenuService, //router here is used in html
    private router: Router
  ) {}

  ngOnInit() {
    this.menuService.getMenu(this.menuId).subscribe((menu) => {
      if (menu.Items && menu.Items.length > 0) {
        this.menu = menu.Items[0].items.map((item) => {
          return {
            ...item,
            external: item.path.trim().search(/(http|https):/) === 0,
          };
        });
        this.menuReady.emit(this.menu);
      }
    });
  }
}
