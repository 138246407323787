<div class="diagram -parent">
  <div class="diagram -sidebar" *ngIf="scenario && !fallbackMode">
    <div class="diagram -scenarios">
      <div *ngFor="let scenario of scenarios; let i = index" class="diagram -scenarios -wrapper form -group-element-wrapper noSelector">
        <!-- <label class="diagram -scenarios -label"> -->
        <label class="diagram -scenarios form -group-element noSelector">
          <input type="radio" [name]="service" [checked]="i === 0" [value]="scenario.name" (change)="selectScenario(i)">
          <span class="form -group-element-radio"></span>
          <span class="form -group-element-label -fontSmall">{{ scenario.name }}</span>
        </label>
      </div>
    </div>
    <div class="diagram -information">
      <label class="diagram -information -title">Information</label>
      <p class="diagram -information -description"> {{ scenario.description }} </p>
    </div>
  </div>
  <div class="diagram -diagram" *ngIf="!fallbackMode">
    <svg [ngClass]="service"></svg>
  </div>
  <div class="diagram -fallback" *ngIf="fallbackMode">
    <img [src]="fallbackImage">
  </div>
</div>