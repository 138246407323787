<ng-container *ngFor="let tabElementRoot of tabElements">
  <tab-container *ngIf="tabElementRoot.children && tabElementRoot.children[0].columnOne && !tabElementRoot.type" class="-secondary">
    <ng-container *ngFor="let tabElement of tabElementRoot.children">
      <tab-item [title]="tabElement.title" [attr.tab-title]="tabElement.title">
        <div class="-container">
          <ng-container *ngIf="tabElement.columnOne">
            <ng-container *ngTemplateOutlet="column; context: {column: tabElement.columnOne}"></ng-container>
          </ng-container>
          <ng-container *ngIf="tabElement.columnTwo">
            <ng-container *ngTemplateOutlet="column; context: {column: tabElement.columnTwo}"></ng-container>
          </ng-container>
          <ng-container *ngIf="tabElement.columnThree">
            <ng-container *ngTemplateOutlet="column; context: {column: tabElement.columnThree}"></ng-container>
          </ng-container>
        </div>
      </tab-item>
    </ng-container>
  </tab-container>
</ng-container>

<ng-template #column let-column="column">
  <div class='-columns'>
    <h4>{{ column.title }}</h4>
    <ng-container *ngFor="let child of column.children">
      <h5>{{ child.title }}</h5>
      <p>{{ child.description }}</p>
      <ng-container *ngFor="let link of child.links">
        <app-link [to]="link.path" [externaloverride]="link.external">{{ link.title }}
          <ng-container *ngIf="link.pdf"><p class="-pdf">PDF</p></ng-container>
        </app-link>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
