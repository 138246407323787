import { TokenService } from './token.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable()
export class DashboardService implements OnDestroy {
  apiKeyHeaders: string[] = ['API', 'API key', 'Expiration Date'];
  apiTokenHeaders: string[] = ['API', 'Account Token', 'Acceptor ID', 'Application ID', 'Account ID'];
  apiAccessTokenHeaders: string[] = ['App Name', 'Access Token', 'Consumer Key', 'Consumer Secret', 'Expiry Time'];
  apiKeys: BehaviorSubject<any> = new BehaviorSubject('empty');
  apiProdKeys: BehaviorSubject<any> = new BehaviorSubject('empty');
  apisFromLicenses: BehaviorSubject<any> = new BehaviorSubject({});
  mappings: BehaviorSubject<any> = new BehaviorSubject({});
  subscription = new Subscription();

  constructor(private configService: ConfigService, private tokenService: TokenService) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async getAndSetTokens() {
    this.apiProdKeys.next('empty');
    this.apiKeys.next('empty');

    this.subscription.add(
      this.configService.getConfig('ss-mapping').subscribe((mappings) => {
        this.mappings.next(mappings.Items[0].mapping);
        this.subscription.add(
          this.tokenService.getAllTokens().subscribe(async (response) => {
            if (response.data && mappings) {
              await this.mergeAPIKeys(response.data, mappings.Items[0].mapping, 'apiKeys');
              await this.setAPIsFromLicenses('apiKeys');
            }
          })
        );

        this.subscription.add(
          this.tokenService.getAllProdTokens().subscribe(async (response) => {
            if (response.data && mappings) {
              await this.mergeAPIKeys(response.data, mappings.Items[0].mapping, 'apiProdKeys');
              // Need to reconfigure when we want analytics for Prod keys
              // await this.setAPIsFromLicenses('apiProdKeys');
            }
          })
        );
      })
    );
  }

  // For analytics
  private async setAPIsFromLicenses(classVariable: string) {
    this.apisFromLicenses.next(
      this[classVariable]
        .getValue()
        .map((x: { appName: string }) => {
          if (this.mappings.getValue()[x.appName].analytics) {
            return {
              apis: this.mappings.getValue()[x.appName].apis,
              appName: this.mappings.getValue()[x.appName].appName,
              apiTitles: this.mappings.getValue()[x.appName].apiTitles,
              apiSpec: this.mappings.getValue()[x.appName].apiSpec,
              mainAPI: x.appName,
            };
          }
        })
        .filter(Boolean)
    );
  }

  private async mergeAPIKeys(responseData: any, mapping: any, classVariable: string) {
    const apiKeyArr = [];
    let multipleIndex = -1;
    let apiObj = {};

    // Merge multiple credential types for one API
    // for (const x of Object.assign([], this.sample.data)) {
    for (const x of Object.assign([], responseData)) {
      // This will NOT work for apps like launchpad that have multiple products.
      //   The wrong apiSpec could be selected for the link. Cross that bridge when
      //   we get there
      if (x && mapping[x.appName]) {
        apiObj = {
          generalAPISpec: mapping[x.appName].apiSpec,
          title: mapping[x.appName].title,
          apiSpecMapping: this.getApiSpecMapping(mapping[x.appName].apiAlias ? mapping[x.appName].apiAlias : mapping[x.appName].apis,mapping[x.appName].apiSpec),
          titleAlias: mapping[x.appName].titleAlias
        };
        if (mapping[x.appName]) {
          if (mapping[x.appName].multiple) {
            if (apiKeyArr.find((y) => y.appName === x.appName)) {
              // Replace for block with line below when IE support is no longer an issue
              // multipleIndex = this.apiKeys.findIndex((y) => y.appName === x.appName);
              for (const y in apiKeyArr) {
                if (x.appName === apiKeyArr[y].appName) {
                  multipleIndex = parseInt(y);
                  break;
                }
              }

              apiKeyArr[multipleIndex].creds.push({
                ...x,
                ...apiObj,
              });
            } else {
              apiKeyArr.push({
                appName: x.appName,
                creds: [x],
                ...apiObj,
              });
            }
          } else {
            apiKeyArr.push({
              ...x,
              ...apiObj,
            });
          }
        }
      }
    }
    this[classVariable].next(apiKeyArr);
  }

  public getApiSpecMapping(title, apiSpec) {
    const indexLength = apiSpec.length;
    const resultantArray = [];
    for (let i=0; i<indexLength; i++) {
      const newObj = {
        path: apiSpec[i],
        title: title[i]
      };
      resultantArray.push(newObj);
    }
    return resultantArray;    
  }
}
