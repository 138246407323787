import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'masthead',
  templateUrl: './masthead.component.html',
  styleUrls: ['./masthead.component.scss'],
})
export class MastheadComponent implements AfterViewInit {
  @Input() inverse = false;
  @Input() image: string;
  @Input() backgroundimage: string;
  @Input() imageSize = '-md';

  constructor(private changeRef: ChangeDetectorRef) {}

  scrollDown() {
    const container = document.getElementsByClassName('masthead -container');

    // if (window.scrollY < container[0].clientHeight * 0.9) {
    const target = window.scrollY + container[0].clientHeight * 0.9;
    window.scrollTo({ top: target, left: 0, behavior: 'smooth' });
    // }
  }

  ngAfterViewInit() {
    this.changeRef.detectChanges();
  }
}
