import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnInit {
  @Input() pager;
  @Input() activePage;
  @Output() onUpdate = new EventEmitter;
  lowerLimit;
  upperLimit;
  pagerMaxLength = 5;
  pagerOffset;

  constructor() { }

  ngOnInit() {
    this.pagerOffset = Math.floor(this.pagerMaxLength / 2);

    this.lowerLimit = 0;
    this.upperLimit = Math.min(this.pagerMaxLength);
  }

  updatePage(page) {

    this.upperLimit = Math.max(this.pagerMaxLength, this.pager.indexOf(page) + this.pagerOffset + 1);
    this.lowerLimit = Math.min(this.pager.length - this.pagerMaxLength, this.pager.indexOf(page) - this.pagerOffset);

    this.onUpdate.emit(page);
  }

  nextPage() {
    const activeIndex = this.pager.indexOf(this.activePage);

    if (activeIndex + 1 !== this.pager.length) {
      this.updatePage(this.pager[activeIndex + 1]);
    }
  }

  previousPage() {
    const activeIndex = this.pager.indexOf(this.activePage);

    if (activeIndex - 1 !== -1) {
      this.updatePage(this.pager[activeIndex - 1]);
    }
  }
}
