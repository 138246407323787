import { ValidatorFn, AbstractControl } from '@angular/forms';

/** A required checkbox group must have atleast one option checked */
export function multiCheckboxRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {

    let checked = false;

    Object.keys(control.value).forEach(checkbox => {
      if (control.value[checkbox]) {
        checked = true;
      }
    });

    return checked ? null : {'multiCheckboxRequired': {value: control.value}} ;
  };
}
