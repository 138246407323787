<h4 [ngClass]="animate"
    (animationend)="animate['fade-animation'] = false"
    *ngIf="largetitle !== 'true'"
    [id]="titleIdentifier">
  {{ title }}
</h4>
<h2 [ngClass]="animate"
    (animationend)="animate['fade-animation'] = false"
    *ngIf="largetitle === 'true'"
    [id]="titleIdentifier">
  {{ title }}
</h2>
<ng-content></ng-content>