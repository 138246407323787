<ul class="-pager">
  <li class="-navigation-button -fontSmall">
    <div *ngIf="activePage > 0" class="-prev-text">
      <link-icon
        icon="thin-arrow-right"
        iconcolor="-colorPrimaryGreen"
        class="left-trans"
        (click)="onPrevPage()">
      </link-icon>
      <span (click)="onPrevPage()">Prev</span>
    </div>
  </li>
  <li class="-pager -excess-icon"
    [class.is-active]="lowerLimit > 0">...</li>
  <li *ngFor="let page of pager">
    <button class="-pager -button" [class.is-active]="page.index === activePage" (click)="updatePage(page.index)" 
      *ngIf="page.index >= lowerLimit && page.index < upperLimit">
      {{ page.label }}
    </button>
  </li>
  <li class="-pager -excess-icon"
    [class.is-active]="upperLimit < pager.length">...</li>
  <li>
  <li class="-navigation-button -fontSmall">
    <div *ngIf="(activePage + 1) < pager.length">
      <!-- <span>Next</span> -->
      <link-icon
        icon="thin-arrow-right"
        iconcolor="-colorPrimaryGreen"
        (click)="onNextPage()">
        Next
      </link-icon>
    </div>
  </li>
</ul>
  