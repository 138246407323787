import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { AnalyticsRequestBody } from '../models/analyticsReqBody.interface';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class AnalyticsService {
  constructor(private apiService: ApiService) {}

  getAnalytics(requestBody: AnalyticsRequestBody) {
    return this.apiService.post('/Analytics', requestBody).pipe(debounceTime(800), distinctUntilChanged());
    // .switchMap(requestBody => this.apiService.post('/Analytics', requestBody));
  }

  getDateFrom(dateTo: Date, range: string) {
    switch (range) {
      case 'Last Day':
        return new Date(new Date(dateTo).setDate(new Date(dateTo).getDate() - 1));
      case 'Last Week':
        return new Date(new Date(dateTo).setDate(new Date(dateTo).getDate() - 7));
      case 'Last Month':
        return new Date(new Date(dateTo).setDate(new Date(dateTo).getDate() - 30));
      default:
        return new Date(new Date(dateTo).setDate(new Date(dateTo).getDate() - 1));
    }
  }
}
