<div [ngSwitch]="template">
  <ng-container *ngSwitchCase="'internal'">
    <a [routerLink]="url" [class.-link-active]="isLinkActive(url)" [queryParams]="tree.queryParams" [fragment]="tree.fragment" (click)="handleClick()">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="'external'">
    <a [href]="to" target="_blank" rel="noopener noreferrer">
      <ng-container *ngTemplateOutlet="content"></ng-container>
      <!-- <span class="wpdh-icon wpdh-icon--external wpdh-icon--margin-left"></span> -->
      <icon icon="external-link" class="-xsm"></icon>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="'noRoute'">
    <a target="_blank" rel="noopener noreferrer">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="'newTab'">
    <a [href]="to" target="_blank" rel="noopener noreferrer">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
  </ng-container>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
