<div class="carousel -container" #container>
  <div class="carousel -nav -left" (click)="slideLeft()" #left>
    <icon icon="singlearrow-left" class="-xs -arrowleft"></icon>
  </div>
  <div class="carousel -slideshow" #carouselItem>
    <ng-content></ng-content>
  </div>
  <div class="carousel -nav -right" (click)="slideRight()" #right>
    <icon icon="singlearrow-right" class="-xs -arrowright"></icon>
  </div>
</div>