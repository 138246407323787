import { LoaderService } from 'app/services/loader.service';
import { CognitoService } from './../../services/cognito.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class LoaderGuard  {
  constructor(public cognitoService: CognitoService, public router: Router, private loaderService: LoaderService) {}

  canActivate(): any {
    this.loaderService.loadingContent.emit(true);
    return true;
  }
}
