import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
})
export class CardComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  @Input() iconsize: string;
  @Input() iconcolor: string;
  @Input() largetitle: string;
  titleIdentifier: string;

  constructor() {}

  ngOnInit() {
    if (this.title) {
      const splittedTitle = this.title.toLowerCase().split(' ');
      this.titleIdentifier = splittedTitle.length > 0 ? splittedTitle.join('-') : null;
    }
  }
}
