import { Component, OnInit, Input } from '@angular/core';
import { MetaService } from 'app/services/meta.service';

@Component({
  selector: 'app-api-versions',
  templateUrl: './api-versions.component.html',
  styleUrls: ['./api-versions.component.scss'],
})
export class APIVersionsComponent implements OnInit {
  @Input() pageRoute: string;
  versions = [];
  showVersions = false;
  mostRecentVersion = true;

  constructor(private metaService: MetaService) {}

  ngOnInit() {
    this.setVersions();
  }

  setVersions() {
    this.pageRoute.split('/').pop() === 'api-specification'
      ? (this.mostRecentVersion = true)
      : (this.mostRecentVersion = false);
    let route;
    // eslint-disable-next-line guard-for-in
    if (this.metaService.metadata.value.versions) {
      for (const x of this.metaService.metadata.value.versions) {
        if (x.toLowerCase() === this.metaService.metadata.value.versions[0]) {
          route = '/' + this.pageRoute.match(/.*(\/api-specification)/g)[0];
        } else {
          route = '/' + this.pageRoute.match(/.*(\/api-specification)/g)[0] + '/' + x.split('.').join('-');
        }
        this.versions.push({
          name: x,
          route: route,
        });
      }
      this.versions[0].name = this.versions[0].name + ' Latest';
    }
  }
}
