import { Component, OnInit } from '@angular/core';
import { PagerComponent} from '../pager/pager.component';

@Component({
  selector: 'search-pager',
  templateUrl: './search-pager.component.html',
  styleUrls: ['./search-pager.component.scss']
})
export class SearchPagerComponent extends PagerComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    this.pagerOffset = Math.floor(this.pagerMaxLength / 2);

    this.calculateBounds();
  }

  updatePage(page) {
    this.activePage = page;

    this.calculateBounds();

    this.onUpdate.emit(this.activePage);
  }

  nextPage() {
    if (this.activePage + 1 !== this.pager.length) {
      this.updatePage(this.activePage + 1);
    }
  }

  previousPage() {
    if (this.activePage - 1 !== -1) {
      this.updatePage(this.activePage - 1);
    }
  }

  firstPage() {
    if (this.activePage !== 0) {
      this.updatePage(0);
    }
  }

  lastPage() {
    if (this.activePage !== this.pager.length - 1) {
      this.updatePage(this.pager.length - 1);
    }
  }

  calculateBounds() {
    this.upperLimit = Math.max(this.pagerMaxLength, this.activePage + this.pagerOffset + 1);
    this.lowerLimit = Math.min(this.pager.length - this.pagerMaxLength, this.activePage - this.pagerOffset);
  }

}
