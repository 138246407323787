import { Subscription } from 'rxjs';
import { LoaderService } from '../../services/loader.service';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '../../services/page.service';
import { MetaService } from '../../services/meta.service';
import { Globals } from '../../globals';
import { fade } from '../../animations/fade';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { FirstPageLoadService } from 'app/services/firstSiteLoad.service';
import { distinctUntilChanged } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/ban-types
// declare let gtag: Function;

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  animations: [fade],
})
export class PageComponent implements OnInit, OnDestroy, AfterViewInit {
  // Env
  env = environment;
  isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  meta = {};
  categories = {};
  body = '';
  hasContent = true;
  loadingContent = true;
  transitionState = 'complete';
  pageRoute = '';
  staticPage = '';
  pageTypes = [];
  firstPageLoad = true;
  isSecNav = false;
  isDocIndex = false;
  navMenuItems = [];
  pageSubscription: Subscription;
  getapikeyInfoModal = false;
  routeRedocSubscription = 0;
  sidebarShow: boolean;

  constructor(
    public globals: Globals,
    public store: Store<any>,
    public loaderService: LoaderService,
    private pageService: PageService,
    private metaService: MetaService,
    private route: ActivatedRoute,
    private firstPageLoadService: FirstPageLoadService,
    private router: Router
  ) {}

  ngOnDestroy() {
    this.pageSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.router.url) {
      const urlParam = this.router.url.split('#id_token');
      if (urlParam.length >= 2) {
        window.location.href = '/';
      }
    }
  }

  ngOnInit() {
    this.route.url.subscribe(() => {
      // Scully integration work
      // Ignore loading service when navigating between anchor tags
      // if (this.pageRoute !== this.route.snapshot.url.map((o) => o.path).join('/')) {
      //   console.log('page component');
      //   this.loaderService.loadingContent.emit(this.firstPageLoadService.loadContent());
      // }
      this.loadingContent = false;
      this.pageRoute = this.route.snapshot.url.map((o) => o.path).join('/');
      if (this.pageSubscription) {
        this.pageSubscription.unsubscribe();
      }
      this.pageSubscription = this.pageService
        .getPage(
          `/${this.route.snapshot.url.map((o) => o.path).join('/')}`.split('?').shift(),
          this.route.snapshot.queryParams.token
        )
        .subscribe(
          (page) => {
            if (!page.Item) {
              this.router.navigateByUrl('/404', { skipLocationChange: true });
            } else {
              this.update(page.Item);
              window.scrollTo({
                top: 0,
                behavior: 'auto',
              });
            }
          },
          (error) => {
            error.status === '403'
              ? this.router.navigateByUrl('/403', { skipLocationChange: true })
              : this.router.navigateByUrl('/404', { skipLocationChange: true });
          }
        );
    });
    this.metaService.metadata.pipe(distinctUntilChanged()).subscribe((pMeta) => {
      this.isSecNav = pMeta && pMeta['sec-nav'] === undefined ? true : pMeta['sec-nav'];
      this.isDocIndex = pMeta && pMeta['page-index'] === undefined ? false : pMeta['page-index'];
    });
    this.pageService.getApiKeyConfirmEvent.subscribe(() => {
      this.getapikeyInfoModal = true;
    });
  }

  sidebarShowHandler(sidebarShow: boolean) {
    this.sidebarShow = sidebarShow;
  }

  update(pageItem: { metadata: any; categories: any; body: string; title: string }) {
    this.sidebarShow = false;
    this.meta = pageItem.metadata || {};
    if (this.meta['template'] === 'redoc') {
      this.routeRedocSubscription++;
    }
    this.metaService.metadata.next(this.meta);
    this.categories = pageItem.categories || {};
    this.body = pageItem.body || '';
    this.hasContent = true;
    this.metaService.setTitle([pageItem.title]);

    // if ('metadata' in pageItem) {
    //   this.metaService.setAllMeta('seo' in pageItem.metadata ? pageItem.metadata.seo : []);
    // }
  }

  // Add googleAnalytics() to (page) and (error) above
  // googleAnalytics() {
  //   if (environment.googleAnalytics !== '') {
  //     gtag('config', environment.googleAnalytics, {
  //       'page_path': window.location.pathname
  //     });
  //   }
  // }
  closeInfoModal(): void {
    this.getapikeyInfoModal = false;
  }
}
