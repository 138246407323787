import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CodeblockService {
  private activeLanguage = new Subject<string>();
  activeLangauge$ = this.activeLanguage.asObservable();

  constructor() {}

  setActiveLanguage(language: string) {
    this.activeLanguage.next(language);
  }
}
