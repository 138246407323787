import { Component, OnInit, Input } from '@angular/core';
import { FileService } from '../../services/file.service';
import * as d3Hierarchy from 'd3-hierarchy';
import * as d3Selection from 'd3-selection';
import * as d3Array from 'd3-array';

@Component({
  selector: 'diagram',
  templateUrl: './diagram.component.html',
  styleUrls: ['./diagram.component.scss']
})
export class DiagramComponent implements OnInit {
  @Input() file: string;
  @Input() w = 640;
  @Input() h = 640;
  @Input() d = 140;
  @Input() m = 80;

  public svg: any;
  public root: any;
  public d3Tree: any;

  public fallbackMode = true;
  public fallbackImage: string;

  public service: string;
  public scenarios: any;
  public scenario: any;
  public resourceTree: any;
  public fileContents: any;
  public loading = false;

  constructor(
    private fileService: FileService
  ) {}

  ngOnInit() {
    this.service = this.file.match(/([^\/.]+)(([^\/]*)(\.[^\/.]+))?$/)[1];

    // d3 v5 doesn't support IE, we need to serve fallback
    // for now, to be replaced by feature detection
    this.fallbackMode = this.detectIE();

    if (this.fallbackMode) {
      this.initFallback();
    } else {
      if (this.file) {
        this.loading = true;
        this.fileService.getFile(this.file).subscribe(file => {
          this.loading = false;
          this.fileContents = JSON.parse(file);
          this.scenarios =  this.fileContents.scenarios;
          this.resourceTree =  this.fileContents.resourceTree;
          this.init();
        }, () => {
          this.loading = false;
          this.initFallback();
        });
      }
    }
  }

  detectIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0) {
      // IE 10 or older => return version number
      return ((parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)) ? true : false);
    }
    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return ((parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)) ? true : false);
    }
    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return ((parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)) ? true : false);
    }
    // other browser
    return false;
  }

  init() {
    this.d3Tree = d3Hierarchy.tree().separation((a, b) => a.parent === b.parent ? 3 : 1).size([this.h, this.w]);
    this.svg = d3Selection.select(`.diagram .-diagram svg.${this.service}`)
    .attr('preserveAspectRatio', 'xMidYMid meet')
    .attr('width', '100%')
    .attr('height', '100%')
    .attr('viewBox', `0 0 ${this.w} ${this.h}`)
    .append('svg:g')
    .style('transform', `translate(0, ${this.m}px) rotate(90deg)`)
    .style('-ms-transform', `translate(0, ${this.m}px) rotate(90deg)`)
    .style('-webkit-transform', `translate(0, ${this.m}px) rotate(90deg)`)
    .style('transform-origin', '50% 50%')
    .style('-ms-transform-origin', '50% 50%')
    .style('-webkit-transform-origin', '50% 50%')

    this.root = d3Hierarchy.hierarchy(this.resourceTree, (d) => d.children );
    this.root.x0 = this.h / 2;
    this.root.y0 = 0;
    this.update(this.root);
    this.selectScenario(0);
  }

  initFallback() {
    this.fallbackImage = this.file.match(/(.*)\.[^.]+$/)[1] + '.jpg';
  }

  update(source) {
    const d3Data = this.d3Tree(source);
    const nodes = d3Data.descendants();
    const links = d3Data.links();
    const diagonal = (s, d) => {
      return `M ${s.y} ${s.x}
              C ${(s.y + d.y) / 2} ${s.x},
                ${(s.y + d.y) / 2} ${d.x},
                ${d.y} ${d.x}`
    }
    const depth = this.d;
    let i = 0;

    nodes.forEach((d) => { d.y = d.depth * depth });

    const node = this.svg.selectAll('g.node').data(nodes, (d) => d.id || (d.id = ++i));

    const link = this.svg.selectAll('g.connector').data(links, (d) => d.id || (d.id = ++i));

    // ****************** Connectors section ***************************

    const connector = link.enter().append('svg:g');

    connector
    .attr('class', (d) => `connector source-${d.source.data.name} target-${d.target.data.name}`);

    connector.append('svg:path')
    .attr('class', 'link')
    .attr('d', (d) => diagonal(d.source, d.target));

    connector.append('rect')
    .attr('class', 'methodBg t')
    .attr('x', (d) => -25 - d3Array.median([d.target.x, d.source.x]) )
    .attr('y', (d) =>  d3Array.median([d.target.y, d.source.y]) )
    .attr('fill', '#eee')
    .attr('width', 50)
    .attr('height', 20)
    .attr('rx', 2)
    .attr('ry', 2)
    .attr('border-radius', 3)
    .attr('transform', 'rotate(-90)');

    connector.append('svg:text')
    .attr('class', 'methodText t')
    .attr('x', (d) => - d3Array.median([d.target.x, d.source.x]) )
    .attr('y', (d) => 15 + d3Array.median([d.target.y, d.source.y]) )
    .style('text-anchor', 'middle')
    .attr('font-size', '14px')
    .attr('transform', 'rotate(-90)');

    // ****************** Nodes section ***************************

    const nodeEnter = node.enter().append('svg:g');

    nodeEnter
    .attr('class', (d) => `node  ${((d.data.type) ? 'type-' + d.data.type : '' )} target-${d.data.name}
    ${((d.depth <= 1) ? ' parent' : ' child')}`)
    .attr('transform', (d) => `translate(${d.y}, ${d.x})`);

    nodeEnter.append('rect')
    .attr('x', (d) => {
      if (!d.parent) {
        return -5;
      } else {
         return 20;
      }
    })
    .attr('y', -50)
    .attr('width', 25)
    .attr('opacity', 1)
    .attr('fill', '#01141a')
    .attr('height', 100);

    nodeEnter.append('svg:circle')
    .attr('r', (d) => ((d.depth < 1) ? 18 : 3))
    .attr('class', 't');

    nodeEnter.append('svg:text')
    .attr('class', 'label t')
    .style('text-anchor', 'middle')
    .attr('transform', 'rotate(-90)')
    .attr('x', 0)
    .attr('y', (d) => {
      if (!d.parent) {
        return 10;
      } else {
         return 38;
      }
    })
    .text((d) => {
      return d.data.name.replace(/[_]/g, '');
    });

    if (this.svg.selectAll('.node.parent.type-user image').empty()) {
      this.svg.selectAll('.node.parent.type-user')
      .append('svg:image')
      .attr('xlink:href', '/assets/icons/user-blue.svg')
      .attr('transform', 'rotate(-90)')
      .attr('width', 20)
      .attr('height', 24)
      .attr('x', -10)
      .attr('y', -30);
    }

    if (this.svg.selectAll('.node.parent.type-api text.subtitle').empty()) {
      this.svg.selectAll('.node.parent.type-api')
      .append('svg:text')
      .style('text-anchor', 'middle')
      .attr('class', 'subtitle')
      .attr('transform', 'rotate(-90)')
      .attr('x', 0)
      .attr('y', 5)
      .text('API');
    }
  }

  selectScenario(i) {
    this.scenario = this.scenarios[i];
    this.changeMethods(this.scenario);
  }

  changeMethods(scenario) {
    const svg = this.svg;

    svg.selectAll('.node, .connector').classed('active get patch post put delete then returns', false);

    if (scenario.path) {
      scenario.path.forEach((path) => {
        svg.selectAll(`.connector.target-${path.name}, .node.target-${path.name}`)
        .classed('active ' + path.method, true);
        svg.selectAll(`.connector.target-${path.name} text.methodText`)
        .text(path.method);
      });
    }

    // Firefox doesn't support 'r' in CSS
    svg.selectAll(`.node circle`)
    .attr('r', 3);
    svg.selectAll(`.node.active circle`)
    .attr('r', 9);
    svg.selectAll(`.node.parent circle`)
    .attr('r', 18);
    svg.selectAll(`.node.parent.active circle`)
    .attr('r', 18);
  }

}