import { MetaService } from '../../services/meta.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoaderService } from 'app/services/loader.service';

@Injectable({ providedIn: 'root' })
export class TitleGuard  {
  constructor(public store: Store<any>, public loaderService: LoaderService, public metaService: MetaService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (route.url.length - 1 >= 0) {
      const title = route.url[route.url.length - 1].path
        .replace(/(^|[\s-])\S/g, function (match) {
          return match.toUpperCase();
        })
        .replace('-', ' ');

      this.metaService.setTitle([title]);
      this.loaderService.loadingContent.emit(true);
    } else {
      // For paths that are '' in the router, have to use state because the ActivatedRouteSnapshop only
      //  sees '' and not the full route
      const title = state.url.replace('-', ' ').replace('/', '');

      this.metaService.setTitle([title[0].toUpperCase() + title.slice(1)]);
      this.loaderService.loadingContent.emit(true);
    }
    return true;
  }
}
