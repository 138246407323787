import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class KeyboardService {
  constructor() {}

  getKeyupObservable() {
    return new Observable<string>((observer) => {
      window.addEventListener('keyup', (e) => {
        observer.next(e.key);
      });
    });
  }
}
