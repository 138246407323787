<button-icon 
  icon="dropup" 
  class="back-to-top"
  iconcolor="-colorSupportingWhite"
  backgroundcolor="-backgroundLightBlue"
  backgroundhovercolor="-backgroundAccentDarkBlue"
  iconattributes="-md -round"
  [@buttonVisibilityState]="buttonState"
  (click)="handleClick($event)">
</button-icon>
<span class="hidden">Back to top</span>
