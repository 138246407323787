<div *ngIf="productSubscription?.isEnabled" class="-subscription">
    <link-icon 
        [icon]="productSubscription.isProcessing === true ? 'refresh' : 'thin-arrow-right'"
        [iconclass]="
        productSubscription.isProcessing === true
        ? '-refresh -infinite-spin -sm15 -colorPrimaryGreen'
        : '-colorPrimaryGreen'
        " 
        linkclass="-thickFont" 
        (click)="subscriptionSubject.next()">
        {{ productSubscription.actionText }}
    </link-icon>
</div>
<div *ngIf="unsubscribeModal" class="-unsubscription-confirm-modal">
    <modal 
        [visible]="true" 
        (visibleChange)="closeConfirmModal()" 
        [secondaryModal]="true"
        [title]="'Unsubscribe from Release Notes?'">
        <div class="-unsubscription-confirm-modal-body">
        <p class="-fontSmall -colorAccentGrey -description-padding">
            If you unsubscribe, you might miss getting ahead of future releases! You can subscribe again
            at any time.
        </p>
        <div>
            <wpbutton (click)="subscriptionAction()">Unsubscribe</wpbutton>
            <wpbutton class="-secondary" (click)="closeConfirmModal()">Cancel</wpbutton>
        </div>
        </div>
    </modal>
</div>

<div *ngIf="infoModal">
  <modal 
    [visible]="true" 
    (visibleChange)="closeInfoModal()" 
    [secondaryModal]="true"
    [title]="'Not logged in !'">
    <div>
    <p class="-fontSmall -colorAccentGrey -description-padding">
        You must login to access this feature
    </p>
    <div>
        <wpbutton type="submit" to="/account/login">Login</wpbutton>
        <wpbutton type="submit" to="/account/register">Create a free account</wpbutton>
    </div>
    </div>
  </modal>
</div>