import { Component, Input } from '@angular/core';

@Component({
  selector: 'wp-form-group',
  templateUrl: './wp-form-group.component.html',
  styleUrls: ['./wp-form-group.component.scss']
})
export class WpFormGroupComponent {
  @Input() question: any;
  @Input() form: any;
  @Input() hideOptional = true;
  @Input() hideCheckboxLabel = true;
  get isValid() { return this.form.controls[this.question.key].valid; }
  get isDirty() { return this.form.controls[this.question.key].dirty; }
  get isTouched() { return this.form.controls[this.question.key].touched; }
  get errors() { return this.form.controls[this.question.key].errors; }

  constructor() { }


}
