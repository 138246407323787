import { Injectable } from '@angular/core';

@Injectable()
export class NavVerticalService {
  constructor() {}

  showNav(nav: any, content: any, slideNav: boolean) {
    if (slideNav) {
      content.nativeElement.classList.add('-slide');
      nav.nativeElement.classList.add('-slide-nav');
    } else {
      content.nativeElement.classList.remove('-slide');
      nav.nativeElement.classList.remove('-slide-nav');
    }
  }
}
