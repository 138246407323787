<div class="version-parent">
  <div class="dropdown">
    <button class="version-button" (click)="showVersions = !showVersions">
      <span>Version</span>
      <button-icon 
        icon="dropdown"
        [ngClass]="showVersions ? 'dropdown-rotate-icon' : '-icon-padding'"
        iconcolor="-colorSupportingWhite"
        iconattributes="-xsm">
      </button-icon>
    </button>
    <div class="dropdown-wrapper" *ngIf="showVersions">
      <div class="dropdown-content" *ngFor="let version of versions; let index = index">
        <a [routerLink]="version.route">
          {{version.name}}
        </a>
      </div>
    </div>
  </div>
</div>
