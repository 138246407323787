import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked, Input } from '@angular/core';

@Component({
  selector: 'cardChain',
  templateUrl: './card-chain.component.html',
  styleUrls: ['./card-chain.component.scss'],
})
export class CardChainComponent implements OnInit, AfterViewChecked {
  @ViewChild('content', { static: true }) content: ElementRef;
  @Input() displayline: boolean;
  @Input() side: string | boolean;
  @Input() position: string;
  chainWidth = '0px';
  parentClass = '';

  constructor(private changeRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.chainWidth = `${(this.content.nativeElement.children.length - 1) * 220 + 100}px`;

    if (this.displayline == null) {
      this.displayline = true;
    }

    if (this.side === 'true') {
      if (this.position === 'centered') {
        this.parentClass = 'd-flex justify-content-center';
      }
      if (this.position === 'between') {
        this.parentClass = 'd-flex justify-content-between';
      }
    }
  }

  ngAfterViewChecked() {
    this.changeRef.detectChanges();
  }
}
