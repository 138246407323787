/* eslint-disable @typescript-eslint/no-this-alias */
import { CognitoAuth } from 'amazon-cognito-auth-js';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { environment } from '../../environments/environment';
import { Location, DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from './loader.service';
import { LocalStorageService } from './localStorage.service';

@Injectable()
export class CognitoService implements OnDestroy {
  constructor(
    private loaderService: LoaderService,
    private location: Location,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private localStorageService: LocalStorageService
  ) {
    // Session handling across multiple tabs
    window.addEventListener('storage', (event) => {
      if (event.key === 'isLoggedIn') {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn === 'false') {
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        }
      }
    });
  }

  poolData = {
    UserPoolId: environment.UserPoolId,
    ClientId: environment.ClientId,
  };

  authData = {
    UserPoolId: environment.UserPoolId,
    AppWebDomain: environment.AppWebDomain,
    ClientId: environment.ClientId,
    RedirectUriSignIn: environment.RedirectUriSignIn,
    RedirectUriSignOut: environment.RedirectUriSignOut,
    TokenScopesArray: environment.TokenScopesArray,
  };

  userPool = new AmazonCognitoIdentity.CognitoUserPool(this.poolData);
  auth = new CognitoAuth(this.authData);
  cognitoUser = null;
  jwt: any;

  ngOnDestroy() {
    window.removeEventListener('storage', () => {}, false);
  }

  getUserInfo() {
    return this.userPool.getCurrentUser();
  }

  getJWT() {
    return this.jwt;
  }

  logout() {
    console.log('hard logout....');
    this.loaderService.loadingContent.emit(true);
    try {
      if (this.auth) {
        this.auth.signOut();
      }
    } catch (err) {
      console.log(err);
    }

    this.cognitoUser = null;
    this.jwt = null;
    this.auth = null;
    this.localStorageService.removeItem('groups');
    this.localStorageService.setItem('isLoggedIn', false);
  }

  createSession() {
    this.cognitoUser = this.userPool.getCurrentUser();
    if (this.cognitoUser !== null) {
      const that = this;
      this.cognitoUser.getSession(function (err: any, session: any) {
        if (err) {
          that.cognitoWebResponse();
        } else {
          that.jwt = session.getAccessToken().getJwtToken();
          that.localStorageService.setItem('isLoggedIn', true);
        }
      });
    } else {
      // Failsafe if congito user does not exist.
      this.cognitoWebResponse();
    }
  }

  cognitoWebResponse() {
    try {
      let curUrl = window.location.href;
      this.auth.parseCognitoWebResponse(curUrl);
      curUrl = curUrl.replace('#', '#?');
      this.jwt = this.auth.signInUserSession.accessToken.jwtToken;
      this.cognitoUser = this.userPool.getCurrentUser();
    } catch (err) {
      if (this.auth.signInUserSession.accessToken.jwtToken !== '') {
        this.jwt = this.auth.signInUserSession.accessToken.jwtToken;
        this.cognitoUser = this.userPool.getCurrentUser();
        this.localStorageService.setItem('isLoggedIn', true);
      }
    }
  }
}
