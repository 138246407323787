import { NgModule } from '@angular/core';

import { BreakpointService } from './breakpoint.service';
import { BreakpointComponent } from './component/breakpoint.component';

@NgModule({
  imports: [],
  providers: [BreakpointService],
  declarations: [BreakpointComponent],
  exports: [BreakpointComponent],
})
export class BreakpointModule {}
