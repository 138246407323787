<div
  [@basicFade]
  *ngIf="visible"
  class="fismodal -opacityBlock"
  [ngClass]="{
    '-modal-pointer': isGuidedModal
  }">
  <div
    class="fismodal -contentContainer"
    [ngClass]="{ '-backgroundSupportingWhite': secondaryModal, '-guided-modal-width': isGuidedModal }">
    <div
      class="fismodal d-flex justify-content-between align-items-end"
      [ngClass]="[!secondaryModal ? '-header' : '-secondary-modal-header', isGuidedModal ? 'guided-modal-header' : '']">
      <div [ngClass]="secondaryModal ? 'fismodal -secondary-modal-margin' : 'col-sm-12 -no-padding'">
        <span *ngIf="!secondaryModal" class="-fontXSmall">{{ category }}</span>
        <h3 class="mt-0 mb-0" [ngClass]="{'fismodal -custom-title': secondaryModal}">{{ title }}</h3>
      </div>
      <div class="fismodal -close" [ngClass]="{'-secondary-padding-bottom': secondaryModal}">
        <icon
          (click)="close()"
          icon="{{ icon }}"
          [ngClass]="{'secondary-height': secondaryModal}">
        </icon>
      </div>
    </div>
    <div>
      <ng-container *ngIf="!guidedModalContents">
        <ng-content></ng-content>
      </ng-container>
      <ng-container *ngIf="guidedModalContents">
        <p
          class="-fontSmall -colorAccentGrey guided-modal -description-padding"
          [ngClass]="{ '-guided-modal-description-padding': isGuidedModal }">
          {{ guidedModalContents.descriptionArray[index] }}
        </p>
        <div class="-modal-footer">
          <div class="-content-text">
            {{ guidedModalContents.footerText }}
          </div>
          <wpbutton (click)="next()">
            {{ guidedModalContents.steps === index ? 'Finish' : 'Next' }}
          </wpbutton>
        </div>
      </ng-container>
    </div>
  </div>
</div>
