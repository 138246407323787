<div class="line-chart -container">
  <div 
    class="line-chart -parent"
    [style.height.px]="chartExtraAttributes.chartHeight"
    [style.width.%]="100">
    <canvas 
      baseChart
      #chartComponent
      id="lineChart" 
      [height]="1"
      [width]="1"
      style="transition: height 500ms linear, width 500ms linear;"
      [datasets]="chartData"
      [options]="lineChartOptions"
      [type]="lineChartType">
    </canvas>
    <div class="line-chart -no-data -fontDisplaySmall" *ngIf="noData">
      No Data
    </div>
  </div>
  <div class="chart-legend -container" [style.max-height.px]="chartExtraAttributes.chartHeight">
    <div class="chart-legend -sort-button" (click)="legendSort()">
      {{ chartExtraAttributes ? chartExtraAttributes.sortingTitleName : null }}
      <icon *ngIf="sortDirection === 'asc'" icon="dropdown" class="-colorSupportingWhite -sm"></icon>
      <icon *ngIf="sortDirection === 'desc'" icon="dropup" class="-colorSupportingWhite -sm"></icon>
    </div>
    <div class="chart-legend -items">
      <div 
        *ngFor="let item of chartData; let i = index"
        class="chart-legend -item" 
        (click)="legendOnClick($event, item, i)">
        <span 
          class="chart-legend -color-bar" 
          [style.backgroundColor]="item.backgroundColor"
          [style.borderColor]="item.borderColor">
        </span>
        <p class="chart-legend -text">
          {{ item.label }}
        </p>
        <p class="chart-legend -total"> 
          {{ chartData[i] !== undefined ? chartData[i].totalOrAvg : 0 }}
        </p>
      </div>
    </div>
  </div>
</div>
