 <div *ngIf="topCollapsableIcon">
  <link-icon
    class="section-collapsable-child -linkCollapse -collapsableIconRight"
    (click)="changeExpandState(target)"
    [icon]="iconPath">
  </link-icon>
</div>
<div class="row section-collapsable-child -content -hiddenRow" [@closed]="closed" #target>
  <ng-content></ng-content>
</div>
<div *ngIf="!topCollapsableIcon">
  <link-icon
    class="section-collapsable-child -linkCollapse"
    (click)="changeExpandState(target)"
    [icon]="iconPath">
      {{ label }}
  </link-icon>
</div>