import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit, OnChanges } from '@angular/core';

@Component({
  selector: 'button-icon',
  templateUrl: './wp-button-icon.component.html',
  styleUrls: ['./wp-button-icon.component.scss'],
})
export class WpButtonIconComponent implements OnInit, OnChanges, AfterViewInit {
  // @Input() backgroundHoverColor = '-backgroundTintPrimaryBlackT60';
  @Input() icon: string;
  @Input() iconcolor: string;
  @Input() iconhovercolor: string;
  @Input() iconattributes: string;
  @Input() backgroundcolor: string;
  @Input() backgroundhovercolor: string;
  iconClass: any;

  constructor(private changeRef: ChangeDetectorRef) {}

  mouseEnter() {
    this.iconClass = `${this.iconhovercolor} ${this.backgroundhovercolor} ${this.iconattributes}`;
  }

  mouseLeave() {
    this.iconClass = `${this.iconcolor} ${this.backgroundcolor} ${this.iconattributes}`;
  }

  ngOnInit() {
    this.updateIconClasses();
  }

  ngAfterViewInit() {
    this.changeRef.detectChanges();
  }

  ngOnChanges() {
    this.updateIconClasses();
  }

  updateIconClasses() {
    if (!this.iconhovercolor) {
      this.iconhovercolor = this.iconcolor;
    }
    if (!this.backgroundhovercolor) {
      this.backgroundhovercolor = this.backgroundcolor;
    }

    this.iconClass = `${this.iconcolor} ${this.backgroundcolor} ${this.iconattributes}`;
  }
}
