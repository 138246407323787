import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { ProductSubscription } from '../models/productSubscription.interface';
import { AccountService } from './account.service';
import { MetaService } from './meta.service';
import { CognitoService } from './cognito.service';
import { LocalStorageService } from '../services/localStorage.service';

@Injectable({ providedIn: 'root' })
export class ProductSubscriptionService {
  constructor(
    private accountService: AccountService,
    private cognitoService: CognitoService,
    private metaService: MetaService,
    private localStorageService: LocalStorageService
  ) {}

  private getActionText(isSubscribed: boolean): string {
    const metaInfo = this.metaService.metadata.getValue();
    return isSubscribed
      ? metaInfo['template'] === 'product'
        ? 'Unsubscribe from release notes'
        : 'Unsubscribe'
      : metaInfo['template'] === 'product'
      ? 'Subscribe to release notes'
      : 'Subscribe';
  }

  private sendSubscription(observer: Subscriber<ProductSubscription>, subscription: ProductSubscription) {
    observer.next(subscription);
    observer.complete();
  }

  subscriptionAction(data): Observable<any> {
    return this.accountService.updateAccount(data);
  }

  getSubscription(): Observable<ProductSubscription> {
    const productSubscription: ProductSubscription = {
      isEnabled: false,
      userSubscribed: false,
      isProcessing: false,
      result: null,
      actionText: 'Subscribe to release notes',
      userEmail: null,
      userSubscriptionGroup: null,
    };

    return new Observable((observer) => {
      this.metaService.metadata.subscribe((metaInfo) => {
        if (metaInfo['subscription-group']) {
          const groupsLocal = this.localStorageService.getItem('groups');
          let groups = groupsLocal ? groupsLocal.split(',') : [];

          productSubscription.isEnabled = true;
          this.accountService.accountInfo.subscribe((account) => {
            if (!groups) return;
            groups = groups.length === 0 ? account.groups : groups;
            productSubscription.userEmail = account && account.email ? account.email : null;
            productSubscription.userSubscriptionGroup = metaInfo['subscription-group'];
            productSubscription.userSubscribed = this.cognitoService.getUserInfo()
              ? groups
                ? groups.includes(metaInfo['subscription-group'])
                : false
              : false;
            productSubscription.actionText = this.getActionText(productSubscription.userSubscribed);
            this.sendSubscription(observer, productSubscription);
          });
        } else {
          this.sendSubscription(observer, productSubscription);
        }
      });
    });
  }

  updateSubscription(subscriptionToUpdate: ProductSubscription): Observable<ProductSubscription> {
    return new Observable((observer) => {
      const data: any = {
        action: !subscriptionToUpdate.userSubscribed ? 'subscribe' : 'unsubscribe',
        group: subscriptionToUpdate.userSubscriptionGroup,
        email: subscriptionToUpdate.userEmail,
      };
      this.subscriptionAction(data).subscribe(
        (resp) => {
          this.updateSubscriptionGroup(subscriptionToUpdate);
          this.sendSubscription(observer, this.formatSubscription(resp, subscriptionToUpdate));
        },
        (error) => {
          this.sendSubscription(observer, this.formatSubscription(error, subscriptionToUpdate));
        }
      );
    });
  }

  updateSubscriptionGroup(subscriptionToUpdate: ProductSubscription) {
    const groupsLocal = this.localStorageService.getItem('groups');
    let groups = groupsLocal ? groupsLocal.split(',') : [];

    if (!subscriptionToUpdate.userSubscribed) {
      groups.push(subscriptionToUpdate.userSubscriptionGroup);
    } else {
      groups = groups.filter((item) => item !== subscriptionToUpdate.userSubscriptionGroup);
    }
    this.localStorageService.setItem('groups', groups);
  }

  private formatSubscription(response: any, subscriptionToUpdate: ProductSubscription): ProductSubscription {
    const updatedSubscription = { ...subscriptionToUpdate };

    if (response) {
      updatedSubscription.result = {
        error: response.error,
        status: 'failure',
        title: 'Error',
        description: 'Sorry, something went wrong processing your request - please try again later',
      };
    } else {
      updatedSubscription.result = {
        error: null,
        status: 'success',
        title: !subscriptionToUpdate.userSubscribed ? 'Subscribed' : 'Unsubscribed',
        description: !subscriptionToUpdate.userSubscribed
          ? 'You have successfully subscribed to release notes and this will go into effect within few minutes.'
          : 'You have successfully unsubscribed from release notes and this will go into effect within few minutes.',
      };

      updatedSubscription.userSubscribed = !subscriptionToUpdate.userSubscribed;
      updatedSubscription.actionText = this.getActionText(updatedSubscription.userSubscribed);
    }
    updatedSubscription.isProcessing = false;
    return updatedSubscription;
  }
}
