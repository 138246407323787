import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ConfigService } from '../../../services/config.service';

@Injectable()
export class RedirectGuard  {
  constructor(protected configService: ConfigService, protected router: Router) {}
  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>((observer) => {
      this.configService.getConfig('redirects').subscribe(
        (response) => {
          if (!response.Items || response.Items.length > 1) {
            observer.next(true);
          } else {
            const config = response.Items[0];

            if (!config || !config.redirects) {
              observer.next(true);
            } else {
              const redirects = config.redirects;
              const url = `/${next.url.join('/')}`;

              if (!redirects[url]) {
                observer.next(true);
              } else {
                this.router.navigateByUrl(redirects[url]);
                observer.next(false);
              }
            }
          }
        },
        () => {
          observer.next(true);
        }
      );
    });
  }
}
