import { Component, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':leave', [style({ opacity: 1 }), animate('0.5s ease-in', style({ opacity: 0 }))]),
      // transition(
      //   ':enter',
      //   [
      //     style({ opacity: 0 }),
      //     animate('0.1s ease-in', style({ opacity: 1 }))
      //   ]
      // )
    ]),
  ],
})
export class LoaderComponent {
  @Input() loadingContent: boolean;
  @Input() showbackground = false;
  constructor() {}

}
