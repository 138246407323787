import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { MastheadCTAComponent } from '../masthead-cta/masthead-cta.component';
import { MetaService } from 'app/services/meta.service';
import { DocPageNotificationService } from 'app/services/docpage-notification.service';

@Component({
  selector: 'masthead-gradient',
  templateUrl: './masthead-gradient.component.html',
})
export class MastheadGradientComponent extends MastheadCTAComponent {
  @Input() img: string;
  @Input() mode: string;

  constructor(changeRef: ChangeDetectorRef, store: Store<any>, private metaService: MetaService, notificationService: DocPageNotificationService) {
    super(changeRef, store, notificationService);
  }

  ngOnInit(): void {
    this.metaService.updateMode(this.mode);
  }
}
