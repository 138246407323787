<div 
  class="masthead -container d-flex align-items-center" 
  [ngStyle]="{ 'background-image': 'url(' + backgroundimage + ')' }">
  <div class="row-no-gutters" [ngClass]="backgroundimage ? 'row -home' : 'row'">
    <div class="col-sm-12 col-md-10 col-md-offset-1">
      <div class="masthead -content-container">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  
  <button-icon
    icon="dropdown"
    iconattributes="-round -xlg"
    iconcolor="-colorPrimaryGreen"
    backgroundcolor="-backgroundTintPrimaryBlackT60 -opacity"
    backgroundhovercolor="-backgroundSupportingWhite -opacity"
    (click)="scrollDown()"
    class="masthead -buttons-scroll-down">
  </button-icon>
  <img *ngIf="image !== undefined" class="masthead -image" [ngClass]="imageSize" [src]="image"/>
</div>