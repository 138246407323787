import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class BreakpointService {
  constructor() {}

  private getBreakpoint(): string {
    let breakpoint;

    const elems = <NodeListOf<HTMLElement>>document.querySelectorAll(
      '[data-breakpoint]',
    );

    for (let i = 0; i < elems.length; i++) {
      const element = elems[i];

      if (element.offsetParent) {
        breakpoint = element.getAttribute('data-breakpoint');
      }
    }

    return breakpoint;
  }

  getBreakpointObservable(): Observable<string> {
    const observable = new Observable<string>(observer => {
      observer.next(this.getBreakpoint());

      let resizeTimeoutId;
      window.addEventListener('resize', () => {
        clearTimeout(resizeTimeoutId);

        resizeTimeoutId = setTimeout(() => {
          observer.next(this.getBreakpoint());
        }, 250);
      });
    }).pipe(distinctUntilChanged());

    return observable;
  }
}
