import { MetaService } from 'app/services/meta.service';
import { Component } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { FragmentService } from '../../services/fragment.service';
import { LoaderService } from 'app/services/loader.service';

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss'],
})
export class BasicComponent extends BaseComponent {
  constructor(
    public fragmentService: FragmentService,
    public loaderService: LoaderService,
    public metaService: MetaService
  ) {
    super(fragmentService, loaderService, metaService);
  }
}
