<!-- <div class="d-flex flex-row" (scroll)="onScroll($event)"> -->

  <div class="product -vertical-nav" #nav>
    <nav-vertical slideNav="slideNav"></nav-vertical>
  </div>
  
  <div class="product -content" #content>
    <content-viewer 
      *ngIf="body" 
      [content]="body" 
      (docRendered)="handleDocRendered($event)">
    </content-viewer>
  </div>
  
  <div class="product -vertical-nav-expand">
    <button-icon 
      icon="arrow" 
      iconcolor="-colorPrimaryGreen"
      backgroundcolor="-backgroundSupportingWhite"
      backgroundhovercolor="-backgroundTintPrimaryBlackT60"
      iconattributes="-lg -round -opacity"
      (click)="showNav()">
    </button-icon>
  </div>