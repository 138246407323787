import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input() count: string;
  @Input() icon: string;

  constructor() {}

  ngOnInit() {
    const doc = document.getElementById('svgElement');
    document
      .getElementById('svgElement')
      .setAttribute('viewBox', `0 0 ${doc.parentElement.clientHeight} ${doc.parentElement.clientWidth}`);
  }
}
