// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  envName: 'staging',
  // front app old URL
  oldAppURL: 'https://staging-developer.fisglobal.com',
  apiHost: 'https://staging-docs.worldpay.com',
  apiKey: 's7O6HjEUVk6nO8QNzUf53aOloXTEphvm9loVWVqY',
  selfcertApiHost: "https://cert-eapi.fisglobal.com",
  sandboxApiHost: 'https://staging-docs.worldpay.com',
  sandboxApiKey: 's7O6HjEUVk6nO8QNzUf53aOloXTEphvm9loVWVqY',
  UserPoolId: 'us-east-2_ZsD5zWsc6',
  // NO HTTPS, ONLY BASE URL
  AppWebDomain: 'staging-developer-fisglobal.auth.us-east-2.amazoncognito.com',
  ClientId: '4ec52jt0scltnukkm6og1s0nmq',
  RedirectUriSignIn: 'https://staging-docs.worldpay.com/index.html',
  RedirectUriSignOut: 'https://staging-docs.worldpay.com/account/logout/',
  TokenScopesArray: ['email', 'phone', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
  instanaKey: '',
  hotjar: '',
  googleAnalytics: '',
  googleAnalyticsUrl: 'https://www.googletagmanager.com/gtag/js?id=',
  content: '../../../catalyst-content/content',
  pendoApiKey: '',
  pendoAccountId: '',
  chatEnabled: true,
  chatUrl: '//worldpayusgw.whoson.com/include.js?domain=developerengine.fisglobal.com',
  chatTokenInterval: 190000,
  // Salesforce web to lead form configurations
  sfLeadSubmissionURL:
    'https://payments--nauat.sandbox.my.salesforce-sites.com/servlet/servlet.WebToLead?encoding=UTF-8', // salesforce web to lead form submission url
  sfOrgId: '00DDW000000O90r', // salesforce web to lead configuration for organization Id
  sfCampaignID: '7013h000000ULBBAA4', // salesforce web to lead configuration for campaign Id
  sfRecordType: '0123h000000MnxaAAC',
  marketoCodeId: '975-BCU-707',
  marketoCodeSrcUrl: '//munchkin.marketo.net/munchkin.js',
  // One Trust Configuration
  functionalCookiedId: 'optanon-category-C0003',
  marketingCookieId: 'optanon-category-C0004',
  performanceCookiedId: 'optanon-category-C0002',
};
