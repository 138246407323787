import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable()
export class MenuService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  getMenu(id: string) {
    return this.apiService.get('/Menus', { id });
  }
}
