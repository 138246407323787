import {
    ActivatedRouteSnapshot, RouteReuseStrategy,
    DetachedRouteHandle
} from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class CustomReuseStrategy extends RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean { return false; }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}

  shouldAttach(route: ActivatedRouteSnapshot): boolean { return false; }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle|null { return null; }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future.routeConfig && curr.routeConfig) {
      return future.routeConfig.path === curr.routeConfig.path && future.routeConfig.component === curr.routeConfig.component;
    }
    return future.routeConfig === curr.routeConfig;
  }
}
