import { Component, OnInit, Input, Output, EventEmitter, Renderer2, HostListener } from '@angular/core';
import { basicFade } from 'app/animations/basicFade';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [basicFade],
})
export class ModalComponent implements OnInit {
  @Input() id: string;
  @Input() category: string;
  @Input() title: string;
  @Input() modal: string;
  @Input() closable = true;
  @Input() visible: boolean | string;
  @Input() secondaryModal: boolean;
  @Input() isGuidedModal: boolean;

  @Input() guidedModalContents: {
    titleArray: string[];
    descriptionArray: string[];
    footerText: string;
    steps: number;
    startGuidedModal: boolean;
  };

  @Output() visibleChange: EventEmitter<boolean | string> = new EventEmitter<boolean | string>();
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() indexValue: EventEmitter<number> = new EventEmitter<number>();
  icon = 'delete';
  index = 0;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    if (this.secondaryModal) {
      this.icon = 'close';
    }
  }

  ngAfterViewInit(): void {
    this.modifyLeftnavZindex(false);
  }

  next() {
    this.renderer.setStyle(this.renderer.selectRootElement('.wpdh-container', true), 'overflow-y', 'hidden');
    if (!this.guidedModalContents.startGuidedModal) {
      this.guidedModalContents.startGuidedModal = true;
    }

    if (this.index === (this.guidedModalContents.steps || 4)) {
      this.close();
    } else {
      this.index++;
      this.guidedModalContents.footerText = `${this.index} of ${this.guidedModalContents.steps || '4'}`;
    }
    this.indexValue.emit(this.index);
  }

  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    if (this.isGuidedModal) {
      this.renderer.removeStyle(this.renderer.selectRootElement('.wpdh-container', true), 'overflow-y');
      this.closeModal.emit();
    }
  }

  ngOnDestroy(): void {
    this.modifyLeftnavZindex(false);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.modifyLeftnavZindex(false);
  }

  // patch to bring modal on top of left nav slider for smaller screens
  modifyLeftnavZindex(showOnTop: boolean): void {
    const leftNavContainer: HTMLElement =
      document.querySelector('.-left-nav-container') || document.querySelector('.-nav-vertical');

    if (!leftNavContainer) {
      return;
    }
    if (window.innerWidth <= 1200) {
      leftNavContainer.style.cssText = showOnTop ? 'z-index: 9 !important' : 'z-index: 2 !important';
    } else {
      leftNavContainer.style.cssText = 'z-index: 2 !important';
    }
  }
}
