import { CognitoService } from './../../services/cognito.service';
import { TokenService } from '../../services/token.service';
import { LocalStorageService } from '../../services/localStorage.service';
import { MetaService } from '../../services/meta.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// This service was created to have a method to call a function through a relative path.
@Injectable({ providedIn: 'root' })
export class SelfServiceGuard {
  constructor(
    public cognitoService: CognitoService,
    public tokenService: TokenService,
    private localStorageService: LocalStorageService,
    private metaService: MetaService,
    private router: Router
  ) {}

  canActivate(): any {
    this.tokenService.createToken().subscribe(() => {
      this.router.navigate(['/dashboard/credentials'], { replaceUrl: true });
    });

    return true;
  }
}
