import { Input, OnChanges, Directive } from '@angular/core';

@Directive()
export class ViewComponent implements OnChanges {
  @Input() hits: any;
  @Input() resultsPerPage = 10;

  total: number;
  pager: any;
  activePage: any;

  constructor() {}

  ngOnChanges() {
    if (this.hits) {
      this.total = this.hits.total;
      this.pager = this.buildPager(this.hits);
      this.activePage = this.pager[0];
    }
  }

  buildPager(hits) {
    const total = hits.total;
    const pages = Math.ceil(total / this.resultsPerPage);

    const pager = [];

    for (let i = 0; i < pages; i++) {
      const start = i * this.resultsPerPage;
      const end = (i + 1) * this.resultsPerPage - 1;
      pager.push({
        index: i,
        label: i + 1,
        start: start,
        end: Math.min(end, total - 1),
        results: hits.hits.slice(start, Math.min(end + 1, total)),
      });
    }

    return pager;
  }

  handlePagerUpdate(page) {
    this.activePage = page;
  }
}
