import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  AUTO_STYLE,
  // ...
} from '@angular/animations';
import { BreakpointService } from 'app/modules/breakpoint/breakpoint.service';

@Component({
  selector: 'section-collapsable',
  templateUrl: './section-collapsable.component.html',
  styleUrls: ['./section-collapsable.component.scss'],
  animations: [
    trigger('closed', [
      state(
        'false',
        style({
          height: AUTO_STYLE,
        })
      ),
      state(
        'true',
        style({
          height: 0,
        })
      ),
      transition('false <=> true', [animate('125ms')]),
    ]),
  ],
})
export class SectionCollapsableComponent implements OnInit {
  @ViewChild('target')
  target: ElementRef;

  closed = true;
  iconPath = 'dropdown';
  label = 'Expand';
  isSmallScreen = false;

  @Input() topCollapsableIcon = false;

  constructor(private breakpointService: BreakpointService) {}

  ngOnInit(): void {
    this.breakpointService.getBreakpointObservable().subscribe((breakpoint) => {
      if (['xs', 'sm', 'md'].indexOf(breakpoint) === -1) {
        this.isSmallScreen = false;
      } else {
        this.isSmallScreen = true;
      }
    });
  }

  changeExpandState() {
    this.closed = !this.closed;

    if (this.closed) {
      const scrollOffset = this.isSmallScreen
        ? this.target.nativeElement.parentElement.parentElement.offsetTop
        : this.target.nativeElement.parentElement.parentElement.offsetTop - 140;
      // Scroll page to the top of the section collapsable header
      window.scrollTo({
        top: scrollOffset,
        behavior: 'auto',
      });
      this.iconPath = 'dropdown';
      this.label = 'Expand';
    } else {
      this.iconPath = 'dropup';
      this.label = 'Collapse';
    }
  }
}
