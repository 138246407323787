import { Component } from '@angular/core';

@Component({
  selector: 'gridColumn',
  templateUrl: './grid-column.component.html',
  styleUrls: ['./grid-column.component.scss']
})
export class GridColumnComponent {
  constructor() {}

}
