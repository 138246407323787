import { Component, Input } from '@angular/core';
import { CardComponent } from '../card/card.component';

@Component({
  selector: 'card-api-convergence',
  templateUrl: './card-api-convergence.component.html'
})
export class CardApiConvergenceComponent extends CardComponent {
  @Input() to: string;
  @Input() externalTo = false;
  @Input() family: string;
  @Input() linktitle: string;
}
