<div class="icon-nav -container" *ngIf="reload">
  <div class="d-flex -menu-icons">
    <div class="icon-nav -container -item -theme-icon" *ngIf="theme">
      <a (click)="switchThemeMode()">
          <icon *ngIf ="(theme == 'light')" icon="moon" class="-theme -moon-dark"></icon>  
          <icon *ngIf ="(theme == 'dark')" icon="sun" class="-theme -sun-light"></icon>
      </a>
    </div>
    <div class="icon-nav -container -item -search-box">
      <a class="">
        <app-search-box></app-search-box>
      </a>
    </div>

    <div class="icon-nav -container -item"
      [ngClass]="icon.icon === 'person' ? '-user-account' : '-user-help'"
      *ngFor="let icon of menuIcons">
      <ng-container *ngIf="icon.externalPath; then externalTemplate; else internalTemplate">
      </ng-container>
      <ng-template #internalTemplate>
        <div class="icon-nav -navIcon" [ngClass]="(icon.icon === 'person' || icon.icon === 'help') ? 'no-close':''" (click)="(icon.icon === 'person' || icon.icon === 'help') && toggleMenu(icon.icon)">
          <span *ngIf="icon.icon === 'person'">
            <span class="icon-nav -item" aria-label="InternalNav">
              <button-icon [icon]="icon.icon" class="{{ profileIcon }}" iconhovercolor="-colorAccentLightBlue"
                [iconcolor]="router.url.includes(icon.path) ? '-colorAccentLightBlue' : '-colorSupportingWhite'">
              </button-icon>
            </span>
          </span>
          <span *ngIf="icon.icon === 'help'">
            <button-icon [icon]="icon.icon"></button-icon>
          </span>
          <account-dropdown *ngIf="icon.icon == 'person'" [headerText]="fullName" class="no-close icon-nav {{mobileDropdown}}"></account-dropdown>
          <account-dropdown [dropdownTo]="icon.children" *ngIf="icon.icon == 'help'" class="no-close icon-nav {{helpDropDown}}"></account-dropdown>
        </div>
      </ng-template>

      <ng-template #externalTemplate>
        <a [href]="icon.externalPath" class="icon-nav -item" aria-label="ExternalNav">
          <button-icon [icon]="icon.icon" iconhovercolor="-colorAccentLightBlue"
            iconcolor="-colorSupportingWhite">
          </button-icon>
        </a>
      </ng-template>
    </div>
  </div>

  <a *ngIf="showLogin" [routerLink]="'/account/login'"
    class="icon-nav -container -item -loginLink">Login</a>
</div>