<div class="section-collapsable -parent" [ngClass]="{'-collapsableBGPrimaryBlack': primaryBlackBG,'-collapsableBGPrimaryBlackS40': primaryBlackBG40}">
  <div class="d-flex justify-content-between align-items-center">
    <div class="p-2">
      <h1 [ngClass]="{'section-collapsable -parent -smallTitle': smallTitle}">{{title}}</h1>
    </div>
    <div class="p-2">
      <link-icon 
        *ngIf="to" 
        icon="thin-arrow-right" 
        [to]="to" 
        externaloverride="true">
        {{linktext}}
      </link-icon>
    </div>
  </div>
  
  <ng-content></ng-content>
</div>

