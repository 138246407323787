<div class="chart-parent -container">
  <tab-container tabContentPadding="-no-side-padding" (selectedTableTitle)="setDateRange($event)">
    <tab-item title="Last Day">
      <app-line-chart
        dateStepSize="day"
        [chartData]="chartData"
        [chartExtraAttributes]="chartExtraAttributes">
      </app-line-chart>
    </tab-item>
    <tab-item title="Last Week">
      <app-line-chart
        dateStepSize="week"
        [chartData]="chartData"
        [chartExtraAttributes]="chartExtraAttributes">
      </app-line-chart>
    </tab-item>
    <tab-item title="Last Month">
      <app-line-chart
        dateStepSize="month"
        [chartData]="chartData"
        [chartExtraAttributes]="chartExtraAttributes">
      </app-line-chart>
    </tab-item>
    <div class="chart-parent -loader">
      <loader [loadingContent]="loadingChartData"></loader>
    </div>
  </tab-container>
</div>
