<div class="breadcrumbs -fontXSmall" *ngIf="showBreadcrumbs">
  <div class="breadcrumbs -content-container" *ngIf="items.length > 1">
    <ul [ngClass]="isApiSpec ? 'breadcrumbs -list api-versions-ul' : 'breadcrumbs -list'">
      <li *ngFor="let item of items; index as i; last as isLast" class="-fontMedium">
        <!-- <icon *ngIf="item.label === 'Home'" [icon]="'home'" [ngClass]="iconColor" class="-sm"> </icon> -->
        <a *ngIf="!isLast" [routerLink]="item.path" (mouseenter)="mouseEnter(i)" (mouseleave)="mouseLeave(i)">
          {{ item.label }}
        </a>
        <span *ngIf="isLast">{{ item.label }}</span>
        <div *ngIf="!isLast" class="breadcrumbs -divider">/</div>
      </li>
    </ul>
    <div *ngIf="isApiSpec" class="d-flex justify-content-end">
      <app-api-versions [pageRoute]="pageRoute"></app-api-versions>
    </div>
  </div>
</div>
