import { ShellComponent } from './templates/shell/shell.component';
import { DashboardComponent } from 'app/pages/_dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions, RouteReuseStrategy } from '@angular/router';
import { APIsPageComponent } from './pages/apis/apis.component';
import { FourOhFourComponent } from './pages/four-oh-four/four-oh-four.component';
import { TitleGuard } from './routes/guards/title.guard';
import { CanDeactivateGuard } from './routes/can-deactivate-guard.service';
import { FourOhThreeComponent } from './pages/four-oh-three/four-oh-three.component';
import { LeadGenComponent } from './pages/lead-gen/lead-gen.component';
import { LeadConfirmationComponent } from './pages/lead-confirmation/lead-confirmation.component';
import { PageComponent } from './pages/page/page.component';
import { CustomReuseStrategy } from './routes/custom-reuse-strategy';
import { SelfServiceGuard } from './routes/guards/self-service.guard';
import { RouteExternalGuard } from './routes/guards/route-external.guard';
import { RedirectGuard } from './routes/guards/old/redirect.guard';
import { LoaderGuard } from './routes/guards/loader.guard';

const routes: Routes = [
  {
    path: 'styleguide',
    loadChildren: () => import('./modules/styleguide/styleguide.module').then((m) => m.StyleguideModule),
  },
  {
    path: 'styleguide2020',
    redirectTo: '/styleguide',
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then((m) => m.AccountModule),
    canActivate: [TitleGuard],
    // canDeactivate: [CanDeactivateGuard],
  },
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: '404',
        canActivate: [TitleGuard],
        component: FourOhFourComponent,
      },
      {
        path: '403',
        canActivate: [TitleGuard],
        component: FourOhThreeComponent,
      },
      {
        path: 'apis',
        component: APIsPageComponent,
        canActivate: [LoaderGuard],
        // canDeactivate: [CanDeactivateGuard],
      },
      // {
      //   path: 'contact-sales',
      //   component: LeadGenComponent,
      //   canActivate: [TitleGuard],
      //   canDeactivate: [CanDeactivateGuard],
      // },
      // {
      //   path: 'contact-confirmation',
      //   component: LeadConfirmationComponent,
      //   canActivate: [TitleGuard],
        // canDeactivate: [CanDeactivateGuard],
      // },
      {
        path: 'dashboard',
        component: DashboardComponent,
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        // canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'solution-finder',
        loadChildren: () =>
          import('./modules/solution-finder/solution-finder.module').then((m) => m.SolutionFinderModule),
        canActivate: [TitleGuard],
        // canDeactivate: [CanDeactivateGuard],
      },
      {
        path: '**',
        component: PageComponent,
        canActivate: [LoaderGuard],
        // canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
];

const config: ExtraOptions = {
    onSameUrlNavigation: 'reload'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
    providers: [
        // CanDeactivateGuard,
        RedirectGuard,
        RouteExternalGuard,
        SelfServiceGuard,
        TitleGuard,
        LoaderGuard,
        {
            provide: RouteReuseStrategy,
            useClass: CustomReuseStrategy,
        },
    ]
})
export class AppRoutingModule {}
