import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { concat } from 'rxjs';
import { of } from 'rxjs';

// const ANIMATION_DURATION = 250;

@Injectable()
export class FragmentService {
  scrolling = false;
  headerOffset = 160;

  constructor(public router: Router, public route: ActivatedRoute) {}

  getEvents() {
    return concat(
      of(this.getFragment()),
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.getFragment())
      )
    ).subscribe((fragment) => {
      setTimeout(() => {
        this.scrollToFragment(fragment);
      });
    });
  }

  getFragment() {
    return this.route.snapshot.fragment;
  }

  scrollToFragment(fragment) {
    if (this.scrolling || !fragment) {
      return;
    }

    this.scrolling = true;

    const element = document.getElementById(fragment);
    // setTimeout(() => {
    //   element.style.paddingTop = '75px';
    //   element.scrollIntoView({
    //     inline: 'center',
    //   });
    //   this.scrolling = false;
    // }, 20);

    let scrollDistance = 0;
    let el: any;

    setTimeout(() => {
      scrollDistance = el = null;
      el = element;

      while (el !== null) {
        scrollDistance += el.offsetTop;
        el = el.offsetParent;
      }

      this.scrollToSmoothly(scrollDistance - this.headerOffset, 200);

      this.scrolling = false;
    }, 20);
  }

  // window.scrollTop behavior is broken for scully, so this is the alternative
  // This function animates the scroll step by step. Annoying but needed
  scrollToSmoothly(pos: number, time: number) {
    const currentPos = window.pageYOffset;
    let start = null;
    if (time == null) time = 500;
    (pos = +pos), (time = +time);
    window.requestAnimationFrame(function step(currentTime) {
      start = !start ? currentTime : start;
      const progress = currentTime - start;
      if (currentPos < pos) {
        window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos);
      } else {
        window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time);
      }
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    });
  }
}
