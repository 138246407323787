<div [@showHide]="isNotificationReady ? 'show' : 'hide'">
  <notification 
    [class]="notification.status === 'failure' ? '-colorAccentPink' : '-colorAccentLightBlue'"
    [title]="notification.title" 
    [showDismissBtn]="true"
    (dismissNotification)="unsetNotification($event)"
    *ngIf="notification && notification.show">
    {{ notification.description }}
  </notification>
</div>
<div class="-docpage-content">
  <div class="-left-pane" [class]="!isDocIndex ? '-full-width' : '-pane-width'">
    <content-viewer
    *ngIf="body" 
    [content]="body" 
    (docRendered)="handleDocRendered($event)">
  </content-viewer>
  </div>
  <div class="-right-pane" *ngIf="isDocIndex">
    <doc-index [indexContainer]="'content-viewer'" [title]="'Within this Page'" *ngIf="docIndexReady"></doc-index>  
  </div>
</div>