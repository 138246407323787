<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <div class="wpdh-404">
        <!-- <banner image="/assets/website-illustrations/new-icons/Select-a-folder.png"
          [reverse]="false">
        </banner> -->
        <h1 class="wpdh-404__title">403</h1>
        <h2>Permission denied</h2>
        <p>Sorry! You don't have permission to access this page.</p>
        <wpbutton to="/">Go to homepage</wpbutton>
      </div>
    </div>
  </div>
</div>
