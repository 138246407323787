<div class="search -results-controls margin-reset-children">
  <h3 [@resultState]="state">
    &#8203;
    <span *ngIf="startIndex && endIndex">{{ startIndex }} - {{ endIndex }} of {{ total }}</span>
  </h3>
  <!-- <ul class="search -results-filters -fontSmall">
    <li *ngFor="let filter of filters">
      <button class="search -results-filter -fontSmall"
        [class.is-active]="filter === activeFilter"
        (click)="selectFilter(filter)">{{ filter.label }}</button>
    </li>
  </ul> -->
</div>
<div *ngIf="activeHits.length > 0; else message;" [@resultState]="state">
  <div *ngFor="let result of activeHits" class="search -result">
    <!-- <p class="wpdh-type-meta">{{ result._source.type | underscores }}</p> -->
    <h3>{{ result._source.title }}</h3>
    <p class="wpdh-type-grey"><small>{{ result._source.route }}</small></p>
    <p *ngIf="result.highlight && result.highlight.body" [innerHTML]="result.highlight.body[0]"></p>
    <a class="search -result-link" [routerLink]="result._source.route" (click)="checkRoute(result._source.route)"></a>
  </div>
</div>

<div *ngIf="pager && pager.length" class="search -pager-wrapper">
  <search-pager 
    [pager]="pager"
    [activePage]="activePage"
    (onUpdate)=handlePagerUpdate($event)>
  </search-pager>
</div>

<ng-template #message>
  <div class="search -no-results">
    <p *ngIf="start">Start typing to search</p>
    <div *ngIf="updating" class="wpdh-loader wpdh-search__loader">
      <loader showbackground="false" loadingContent="updating"></loader>
    </div>
    <div *ngIf="noResults">
      <p>Sorry! We couldn't find any results for your search!</p>
      <p>Try a less specific search term</p>
    </div>
  </div>
</ng-template>
