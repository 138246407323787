import { Tab } from '../../../models/tab.interface';
import {
  EventEmitter,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  Input,
  Output,
} from '@angular/core';
import { CodeblockService } from '../../../services/codeblock.service';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

const ACTIVE_CLASS = 'is-active';

// For dynamic tab titles, titles must be set in the id attribute
@Component({
  selector: 'tab-container',
  templateUrl: './tab-block.component.html',
  styleUrls: ['./tab-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabBlockComponent implements AfterViewInit {
  @ViewChild('content') content: ElementRef;
  @ViewChild('nav') nav: ElementRef;
  @Input() tabContentPadding: string;
  @Input() spacebetween = false;
  @Output() selectedTableTitle: EventEmitter<any> = new EventEmitter<any>();
  selectedTab: Tab;
  selectedLanguage: string;
  tabs: Tab[] = [];
  items: HTMLElement[];

  constructor(private codeblockService: CodeblockService, private changeRef: ChangeDetectorRef, router: Router) {
    router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      if (this.tabs && this.tabs[0] && this.tabs[0].title && event.url != router.url) {
        this.selectActiveTab(this.tabs[0].title);
      }
    });
  }

  ngAfterViewInit() {
    let title;
    try {
      this.items = this.content.nativeElement.children;

      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].localName === 'tab-item') {
          title = Array.from(this.items[i].attributes).filter((x) => x.localName.includes('title'))[0].value;

          const tab = {
            title,
            contents: this.items[i].querySelector('[data-contents]'),
          };

          // Remove annoying general tooltip that contains title
          // this.items[i].removeAttribute('title');

          this.tabs.push(tab);
        }
      }

      this.selectActiveTab(this.tabs[0].title);
    } catch (e) {
      console.log(e);
    }
  }

  // eslint-disable-next-line
  ngAfterViewChecked() {
    this.changeRef.detectChanges();
  }

  selectActiveTab(activeTab: string) {
    this.selectedLanguage = activeTab;

    this.tabs.forEach((tab) => {
      if (tab.title === activeTab) {
        tab.contents.classList.add(ACTIVE_CLASS);
        this.selectedTab = tab;
        this.selectedTableTitle.emit(tab);
      } else {
        tab.contents.classList.remove(ACTIVE_CLASS);
      }
    });
  }

  onSelect(tab: string) {
    this.codeblockService.setActiveLanguage(tab);
    this.selectActiveTab(tab);
  }
}
