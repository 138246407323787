import { Component, Input } from '@angular/core';

@Component({
  selector: 'engineTable',
  templateUrl: './wp-table.component.html',
  styleUrls: ['./wp-table.component.scss'],
})
export class WpTableComponent {
  @Input() allowSort = false;
  @Input() copyKeyLink = false;
  @Input() externalIcon = false;
  @Input() fixCellWidth = false;
  injectedBody: string;
  sortAsc = {};

  constructor() {}

  sortByObject(header: string, sortAsc, apiData) {
    this.sortAsc = sortAsc;
    this.iconControl(header);
    this.sortColumnData(header, header, apiData);
  }

  sortColumnData(header: string, index: string | number, data: any[]) {
    if (!this.sortAsc[header]) {
      data.sort((a, b) => {
        if (a[index] < b[index]) {
          return -1;
        }
        if (a[index] > b[index]) {
          return 1;
        }
        return 0;
      });
    } else {
      data.sort((a, b) => {
        if (a[index] > b[index]) {
          return -1;
        }
        if (a[index] < b[index]) {
          return 1;
        }
        return 0;
      });
    }
  }

  iconControl(tableHeader: string) {
    for (const x in this.sortAsc) {
      if (x === tableHeader) {
        if (this.sortAsc[x] === true) {
          this.sortAsc[x] = false;
        } else {
          this.sortAsc[x] = true;
        }
      } else {
        this.sortAsc[x] = false;
      }
    }
  }
}
