import { Component, Input } from '@angular/core';

@Component({
  selector: 'testimonial-item',
  templateUrl: './testimonial-item.component.html',
})
export class TestimonialItemComponent {

  @Input() image;

  constructor() {}
}
