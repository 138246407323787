import { MetaService } from 'app/services/meta.service';
import { NavVerticalService } from './../../services/nav-vetical.service';
import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { FragmentService } from 'app/services/fragment.service';
import { LoaderService } from 'app/services/loader.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent extends BaseComponent {
  @ViewChild('nav') nav: ElementRef;
  @ViewChild('content') content: ElementRef;
  slideNav = false;

  constructor(
    public fragmentService: FragmentService,
    public loaderService: LoaderService,
    public metaService: MetaService,
    private navVerticalService: NavVerticalService
  ) {
    super(fragmentService, loaderService, metaService);
  }

  // For mobile
  showNav() {
    this.slideNav = !this.slideNav;
    this.navVerticalService.showNav(this.nav, this.content, this.slideNav);
  }
}