import { SearchService } from 'app/services/search.service';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ViewComponent } from '../view/view.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.scss'],
  animations: [
    trigger('resultState', [
      state(
        'show',
        style({
          opacity: '1',
        })
      ),
      state(
        'hide',
        style({
          opacity: '0',
        })
      ),
      transition('hide <=> show', animate('100ms linear')),
    ]),
  ],
})
export class SearchViewComponent extends ViewComponent implements OnInit, OnChanges {
  filters = [
    {
      label: 'All content',
      value: 'all',
    },
    {
      label: 'Guides',
      value: 'guide',
    },
    {
      label: 'API Reference',
      value: 'api_reference',
    },
  ];
  activeFilter: any;
  startIndex: number;
  endIndex: number;
  state = 'hide';
  activeHits: any[] = [];

  @Input() start: boolean;
  @Input() updating: boolean;
  @Input() noResults: boolean;
  @Input() activePage: number;

  @Output() onFilter = new EventEmitter<any>();
  @Output() onPager = new EventEmitter<any>();

  constructor(private router: Router, private searchService: SearchService) {
    super();
  }

  ngOnInit() {
    this.activeFilter = this.filters[0];
  }

  ngOnChanges() {
    if (this.hits && this.hits.hits) {
      this.hits.hits = this.processHighlights(this.hits.hits);

      // Subtract 1 to fix offset
      this.total = this.hits.total;
      this.pager = this.buildPager(this.hits);

      if (!this.activePage) {
        this.activePage = 0;
      }

      this.startIndex = this.activePage * 10 + 1;
      this.endIndex = this.activePage * 10 + this.hits.hits.length;

      this.activeHits = this.hits.hits;

      setTimeout(() => {
        this.state = 'show';
      });
    } else {
      this.state = 'hide';

      setTimeout(() => {
        this.activeHits = [];
        this.startIndex = null;
        this.endIndex = null;
        this.pager = null;
      }, 100);
    }
  }

  buildPager(hits) {
    const total = hits.total;
    const pages = Math.ceil(total / this.resultsPerPage);
    const pager = [];

    if (total > this.resultsPerPage) {
      for (let i = 0; i < pages; i++) {
        const start = i * this.resultsPerPage;
        const end = (i + 1) * this.resultsPerPage - 1;
        pager.push({
          index: i,
          label: i + 1,
          start: start,
          end: Math.min(end, total - 1),
        });
      }
    }

    return pager;
  }

  selectFilter(filter) {
    this.activeFilter = filter;

    this.onFilter.emit(filter);
  }

  handlePagerUpdate(page) {
    this.activePage = page;

    this.onPager.emit(page);
  }

  processHighlights(results) {
    return results.map((result) => {
      if (result.highlight && result.highlight.body) {
        result.highlight.body[0] = result.highlight.body[0].replace(result._source.title, '');
      }
      return result;
    });
  }

  checkRoute(route: string) {
    this.searchService.toggleSearch();
  }
}
