/* eslint-disable max-classes-per-file */
import { NotificationsComponent } from '../../notifications/notifications.component';
import { WpButtonIconComponent } from '../../wp-button-icon/wp-button-icon.component';
import { SectionCollapsableParentComponent } from '../section-collapsable-parent/section-collapsable-parent.component';
import { SectionCollapsableComponent } from '../section-collapsable/section-collapsable.component';
import { AccordionComponent } from '../../accordion/accordion.component';
import { AccordionConvergenceComponent } from '../../accordion-convergence/accordion-convergence.component';
import { AccordionConvergenceNewComponent } from '../../accordion-convergence-new/accordionConvergence.component';
import { WorkflowTaskComponent } from '../workflow-task/workflow-task.component';
import { WorkflowComponent } from '../workflow/workflow.component';
import { WorkflowCardComponent } from '../workflow-card/workflow-card.component';
import { PillHolderCollapsableComponent } from '../pill-holder-collapsable/pill-holder-collapsable.component';
import { PillComponent } from '../pill/pill.component';
import { CardAPIComponent } from '../../_cards/card-api/card-api.component';
import { CardProductComponent } from '../../_cards/card-product/card-product.component';
import { LinkIconComponent } from '../link-icon/link-icon.component';
import { IconTileComponent } from '../icon-tile/icon-tile.component';
import { CardChainComponent } from '../../_cards/card-chain/card-chain.component';
import {
  Component,
  ComponentFactoryResolver,
  Injector,
  ElementRef,
  Output,
  Input,
  EventEmitter,
  ComponentFactory,
  ComponentRef,
  OnDestroy,
  DoCheck,
  Injectable,
} from '@angular/core';
import { LinkComponent } from '../link/link.component';
import { NoteComponent } from '../../note/note.component';
import { CodeBlockComponent } from '../../code-block/code-block.component';
import { CodeBlockItemComponent } from '../../code-block-item/code-block-item.component';
import { MastheadComponent } from '../../masthead/masthead.component';
import { MastheadCenteredComponent } from '../../masthead-centered/masthead-centered.component';
import { MastheadGradientComponent } from '../../masthead-gradient/masthead-gradient.component';
import { MastheadBasicComponent } from '../../masthead-basic/masthead-basic.component';
import { SmallImageCardComponent } from '../../_cards/small-image-card/small-image-card.component';
import { SmallIconTileComponent } from '../small-icon-tile/small-icon-tile.component';
import { CarouselComponent } from '../carousel/carousel.component';
import { TestimonialComponent } from '../../testimonial/testimonial.component';
import { TestimonialItemComponent } from '../../testimonial/testimonial-item/testimonial-item.component';
import { SlidingButtonComponent } from '../../sliding-button/sliding-button.component';
import { ArticleImageCardComponent } from '../../article-image-card/article-image-card.component';
import { GridComponent } from '../../grid/grid.component';
import { GridColumnComponent } from '../../grid-column/grid-column.component';
import { WpTableComponent } from '../../wp-table/wp-table.component';
import { WpButtonComponent } from '../wp-button/wp-button.component';
import { WpFormComponent } from '../../_form/wp-form/wp-form.component';
import { TabBlockComponent } from '../tab-block/tab-block.component';
import { TabBlockItemComponent } from '../tab-block-item/tab-block-item.component';
import { ModalComponent } from '../modal/modal.component';
import { MastheadCTAComponent } from '../../masthead-cta/masthead-cta.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { CardBasicComponent } from '../../_cards/card-basic/card-basic.component';
import { CardCapabilityComponent } from '../../_cards/card-capability/card-capability.component';
import { IconComponent } from '../icon/icon.component';
import { DiagramComponent } from '../../diagram/diagram.component';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { SecondaryMastheadComponent } from '../../secondary-masthead/secondary-masthead.component';
import { BaseCardComponent } from '../../_cards/base-card/base-card.component';
import { RestVerbBoxComponent } from '../rest-verb-box/rest-verb-box.component';
import { WorkflowTaskConvergenceComponent } from '../workflow-task-convergence/workflow-task-convergence.component';
import { WorkflowCardConvergenceComponent } from '../workflow-card-convergence/workflow-card-convergence.component';
import { DiagramConvergenceComponent } from 'app/components/diagram-convergence/diagram-convergence.component';
import { LeadGenComponent } from 'app/pages/lead-gen/lead-gen.component';

// import { ModalBoxComponent } from '../modal-box/modal-box.component';
// import { AlertComponent } from '../alert/alert.component';
// import { BannerComponent } from '../banner/banner.component';
// import { ArticleMetaComponent } from '../article-meta/article-meta.component';
// import { JourneyDiagramComponent } from '../journey-diagram/journey-diagram.component';
// import { DiagramComponent } from '../diagram/diagram.component';
// import { GalleryComponent } from '../gallery/gallery.component';
// import { GalleryItemComponent } from '../gallery-item/gallery-item.component';
// import { CtaBlockComponent } from '../cta-block/cta-block.component';
// import { CtaComponent } from '../cta/cta.component';
// import { CalloutComponent } from '../callout/callout.component';
// import { ImageCardComponent } from '../image-card/image-card.component';
// import { BasicCardComponent } from '../basic-card/basic-card.component';
// import { EmbeddableComponent } from '../embeddable/embeddable.component';
export const embeddedComponents = [
  IconComponent,
  WpButtonIconComponent,
  NoteComponent,
  CodeBlockComponent,
  CodeBlockItemComponent,
  MastheadGradientComponent,
  MastheadBasicComponent,
  MastheadComponent,
  MastheadCenteredComponent,
  SmallImageCardComponent,
  SmallIconTileComponent,
  CarouselComponent,
  TestimonialComponent,
  TestimonialItemComponent,
  SlidingButtonComponent,
  ArticleImageCardComponent,
  GridComponent,
  GridColumnComponent,
  WpTableComponent,
  WpButtonComponent,
  WpFormComponent,
  TabBlockComponent,
  TabBlockItemComponent,
  ModalComponent,
  MastheadCTAComponent,
  BreadcrumbsComponent,
  CardChainComponent,
  IconTileComponent,
  LinkComponent,
  LinkIconComponent,
  CardBasicComponent,
  CardCapabilityComponent,
  CardAPIComponent,
  CardProductComponent,
  PillComponent,
  PillHolderCollapsableComponent,
  WorkflowCardComponent,
  WorkflowComponent,
  WorkflowTaskComponent,
  AccordionComponent,
  AccordionConvergenceComponent,
  AccordionConvergenceNewComponent,
  SectionCollapsableComponent,
  SectionCollapsableParentComponent,
  NotificationsComponent,
  DiagramComponent,
  TooltipComponent,
  SecondaryMastheadComponent,
  BaseCardComponent,
  RestVerbBoxComponent,
  WorkflowCardConvergenceComponent,
  WorkflowTaskConvergenceComponent,
  DiagramConvergenceComponent,
  LeadGenComponent,
];

@Injectable()
export class EmbeddedComponents {
  components = embeddedComponents;
}

@Component({
  selector: 'content-viewer',
  template: '',
})
export class ContentViewerComponent implements OnDestroy, DoCheck {
  private hostElement: HTMLElement;
  private embeddedComponentFactories: Map<string, ComponentFactory<any>> = new Map();
  private embeddedComponents: ComponentRef<any>[] = [];

  @Output() docRendered = new EventEmitter();

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    elementRef: ElementRef,
    // eslint-disable-next-line no-shadow
    embeddedComponents: EmbeddedComponents,
    private injector: Injector
  ) {
    this.hostElement = elementRef.nativeElement;
    embeddedComponents.components.forEach((component) => {
      const factory = componentFactoryResolver.resolveComponentFactory(component);
      this.embeddedComponentFactories.set(factory.selector, factory);
    });

    const linkFactory = componentFactoryResolver.resolveComponentFactory(LinkComponent);
    this.embeddedComponentFactories.set('a', linkFactory);
  }

  @Input()
  set content(content) {
    this.ngOnDestroy();
    if (content) {
      this.build(content);
      this.docRendered.emit();
    }
  }

  private build(content) {
    this.hostElement.innerHTML = content || '';

    if (!content) {
      return;
    }

    this.embeddedComponentFactories.forEach((factory, selector) => {
      const embeddedComponentElements = this.hostElement.querySelectorAll(selector);

      for (let i = 0; i < embeddedComponentElements.length; ++i) {
        let element = embeddedComponentElements[i];

        // Replace links with link components
        if (
          selector === 'a' &&
          element.parentElement &&
          element.parentElement.nodeName &&
          element.parentElement.nodeName !== 'ARTICLE-IMAGE-CARD'
        ) {
          const oldElement = element;
          element = document.createElement('app-link');
          element.innerHTML = oldElement.innerHTML;
          // setAttributeNS does not convert attribute name to lowercase
          element.setAttribute('to', oldElement.getAttribute('href'));
          element.setAttribute('class', '-content-link');
          oldElement.parentNode.replaceChild(element, oldElement);
        }

        // convert NodeList into an array, since Angular dosen't like having a NodeList passed
        // for projectableNodes
        const projectableNodes = [Array.prototype.slice.call(element.childNodes)];

        const embeddedComponent = factory.create(this.injector, projectableNodes, element);

        // apply inputs into the dynamic component
        // only static ones work here since this is the only time they're set
        for (const attr of (element as any).attributes) {
          if (attr) {
            if (attr.nodeName.indexOf('[') > -1) {
              embeddedComponent.instance[attr.nodeName.replace(/\[|\]/g, '')] = JSON.parse(attr.nodeValue);
            } else {
              embeddedComponent.instance[attr.nodeName] = attr.nodeValue;
            }
          }
        }

        this.embeddedComponents.push(embeddedComponent);
        // Add state class for each component inside the host element
        this.hostElement.classList.add(`has-${selector}`);
      }
    });
  }

  ngDoCheck() {
    this.embeddedComponents.forEach((comp) => comp.changeDetectorRef.detectChanges());
  }

  ngOnDestroy() {
    // destroy these components else there will be memory leaks
    this.embeddedComponents.forEach((comp) => {
      return comp.destroy();
    });
    // Remove state classes from host element
    this.embeddedComponentFactories.forEach((factory, selector) =>
      this.hostElement.classList.remove(`has-${selector}`)
    );
    this.embeddedComponents.length = 0;
  }
}
