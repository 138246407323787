import { WorkflowIconService } from '../../../services/workflow-icon.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'workflow-task-convergence',
  templateUrl: './workflow-task-convergence.component.html',
})
export class WorkflowTaskConvergenceComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() status = 'inactive';
  @Input() title: string;
  @Input() step: string;
  @Input() description: string;
  @ViewChild('contentWrapper') contentWrapper : ElementRef;

  iconProperties = {
    iconPath: '',
    className: '',
  };
  isApiPage = false;
  constructor(private iconService: WorkflowIconService, private route: Router) {}

  ngOnInit() {
    this.isApiPage = (this.route.routerState.snapshot.url).includes('/dashboard/go-live') ? false : true;
    if(!this.isApiPage){
      this.iconProperties = this.iconService.changeIcon(this.status, 'task');
    }  
  }

  ngAfterViewInit() {
    if(this.contentWrapper.nativeElement.childElementCount === 0 && this.isApiPage) {
      this.contentWrapper.nativeElement.classList.remove('-links');
      this.contentWrapper.nativeElement.classList.add('-links-hide');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.status && !this.isApiPage) {
      this.iconProperties = this.iconService.changeIcon(changes.status.currentValue, 'task');
    }
  }
}
