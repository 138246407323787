<ul *ngIf="tabs.length > 1" [ngClass]="spacebetween ? 'tab-nav -spaceBetween' : 'tab-nav'" #nav>
  <ng-container *ngFor="let tab of tabs">
    <li *ngIf="tab" [ngClass]="spacebetween ? '-spaceBetweenFlex' : ''" [class.is-active]="tab.title === selectedLanguage">
      <button type="button" (click)="onSelect(tab.title)" [class.is-active]="tab.title === selectedLanguage">
        {{ tab.title }}
      </button>
    </li>
  </ng-container>
</ul>
<div [ngClass]="'tab-content ' + tabContentPadding">
  <div #content>
    <ng-content></ng-content>
  </div>
</div>
