<!-- <button class="wpdh-search-box"
  (click)="toggleSearch()">
  <span *ngIf="isTouch">Search</span>
  <span *ngIf="!isTouch">Type <span class="wpdh-type-key">S</span> to search</span>
  <img src="/assets/icons/search-grey.svg" alt="Search icon">
</button> -->

<button-icon 
  (click)="toggleSearch()"
  icon="search"
  [iconattributes]="isSmall ? '-sm15' : ''">
</button-icon>
