// import { isScullyRunning } from '@scullyio/ng-lib';
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable()
export class MetaService {
  metadata: BehaviorSubject<any> = new BehaviorSubject({});

  private separator = ' | ';
  private baseTitle = '';
  private modeMastHead = new Subject<string>();

  constructor(protected meta: Meta, protected title: Title) {
    this.baseTitle = this.title.getTitle();
  }

  setTitle(parts: string[] = []) {
    // if (!isScullyRunning()) {
      const formattedTitle = parts
        .concat([this.baseTitle])
        .filter((item) => item)
        .join(this.separator);

      this.title.setTitle(formattedTitle);
    // }
  }

  getTitle() {
    return this.title.getTitle();
  }

  setMeta(tags: any = []): void {
    tags.map((item) => {
      this.meta.addTag(item);
    });
  }

  setAllMeta(meta: any = {}): void {
    const tags = [];

    for (const prop in meta) {
      if (prop && meta[prop]) {
        tags.push({
          name: prop,
          content: meta[prop],
        });
      }
    }
    this.setMeta(tags);
  }

  public getMode(): Observable<string> {
    return this.modeMastHead.asObservable();
  }

  public updateMode(mode: string): void {
    this.modeMastHead.next(mode);
  }
}
