<ng-container *ngIf="type === 'default'">
  <ng-container *ngTemplateOutlet="logo"></ng-container>
</ng-container>

<ng-container *ngIf="type === 'global'">
  <ng-container *ngTemplateOutlet="logo"></ng-container>
  <span></span>
  <!-- <span>Developer</span> -->
  <span>
    <ng-container *ngTemplateOutlet="devtext"></ng-container>
  </span>
</ng-container>

<ng-template #logo>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
	  viewBox="0 0 360.8 97.2"
    style="enable-background:new 0 0 360.8 97.2;"
    xml:space="preserve">
    <g>
      <path class="st0" d="M98.8,27.3c-11.5,0-20.5,9.4-20.5,21.5c0,11.9,9,21.2,20.5,21.2c11.5,0,20.5-9.3,20.5-21.2
        C119.4,36.7,110.4,27.3,98.8,27.3z M98.8,61.3c-6.1,0-10.7-5.4-10.7-12.5c0-7.3,4.6-12.8,10.7-12.8c6.2,0,10.8,5.5,10.8,12.8
        C109.6,55.9,105,61.3,98.8,61.3z M169.2,10.1h-7.7c-0.6,0-1.1,0.5-1.1,1.1v56.3c0,0.6,0.5,1.1,1.1,1.1h7.7c0.6,0,1.1-0.5,1.1-1.1
        V11.3C170.4,10.7,169.9,10.1,169.2,10.1z M152.6,28.3c-1.8-0.4-4.8-1-9.3-1c-7.9,0-15.9,2.5-15.9,15v25.4c0,0.6,0.5,1.1,1.1,1.1
        h7.7c0.6,0,1.1-0.5,1.1-1.1c0,0,0-23.5,0-25.1c0-1.8,0.3-6.4,7.2-6.4c2.8,0,5.6,0.5,6.2,0.6c0.6,0.1,1.1-0.1,1.3-0.7l1.7-6.2
        C153.9,29,153.5,28.5,152.6,28.3z M73.8,28.5h-8.2c-0.5,0-1,0.3-1.1,0.8l-7.8,27.5l-7.1-24.5c-0.6-2.3-2.7-3.9-5.1-3.9H42
        c-2.4,0-4.5,1.6-5.1,3.9l-7.1,24.5l-7.8-27.5c-0.1-0.5-0.6-0.8-1.1-0.8h-8.2c-0.8,0-1.3,0.7-1.1,1.5l11.2,35
        c0.7,2.2,2.8,3.7,5.1,3.7h3.3c2.4,0,4.5-1.6,5.1-3.8l6.9-23.4l6.9,23.4c0.7,2.3,2.8,3.8,5.1,3.8h3.3c2.3,0,4.4-1.5,5.1-3.7l11.2-35
        C75.1,29.2,74.5,28.5,73.8,28.5z M348.1,28.5h-7.8c0,0-0.9,0-1.2,0.8l-10.7,28.4l-10.7-28.4c-0.2-0.4-0.6-0.7-1.1-0.7h-7.8
        c-1.1,0-1.4,0.9-1.2,1.6l15.7,39.4l-6.3,16c-0.3,0.8,0,1.6,1.1,1.6h7.7c0.5,0,0.9-0.3,1.1-0.7l22.5-56.2
        C349.6,29.2,348.9,28.5,348.1,28.5z M214.3,10.1h-7.7c-0.6,0-1.1,0.5-1.1,1.1v16.9c-1.5-0.3-4.1-0.9-8.4-0.9
        c-15.3,0-18.5,13.7-18.5,21.2c0,16.6,10,21.5,19.3,21.5c8,0,13.4-1.9,16-3c1.6-0.7,1.6-1.6,1.6-2.4V11.3
        C215.4,10.7,214.9,10.1,214.3,10.1z M205.5,60.4c0,0-3,0.9-6.5,0.9c-8,0-10-5.7-10-12.4c0-2.4-0.3-12.9,9.6-12.9
        c3.3,0,6.8,0.9,6.8,0.9V60.4z M286.6,27.3c-6.8,0-10.6,0.9-14.3,1.9c-1,0.3-1.4,1-1.1,2.1l1.1,4.7c0.1,0.7,0.8,1.6,1.9,1.3
        c2.4-0.6,6-1.8,11.4-1.8c5.4,0,6.8,2,6.8,6.8h-6.6c-14.1,0-18,7.4-18,13.5c0,7.7,3.7,14.2,17,14.2c10,0,15-2.5,16.1-3.1
        c0.9-0.5,1.4-0.8,1.4-2.4V41.2C302.2,30.7,296,27.3,286.6,27.3z M292.2,61c0,0-3.1,1-7.2,1c-5.7,0-7.4-2.9-7.4-5.9
        c0-3.8,2.5-6.3,8-6.3h6.6V61z M242.3,27.3c-8,0-13.4,1.9-16,3c-1.6,0.7-1.6,1.6-1.6,2.4v53.2c0,0.6,0.5,1.1,1.1,1.1h7.7
        c0.6,0,1.1-0.5,1.1-1.1V69.1c1.5,0.3,4.1,0.9,8.4,0.9c15.3,0,18.5-13.7,18.5-21.2C261.6,32.1,251.6,27.3,242.3,27.3z M241.6,61.2
        c-3.3,0-6.8-0.9-6.8-0.9V36.8c0,0,3-0.9,6.5-0.9c8,0,10,5.7,10,12.4C251.1,50.7,251.4,61.2,241.6,61.2z"/>
    </g>
  </svg>
</ng-template>

<ng-template #devtext>
  <svg
    width="89"
    height="18"
    viewBox="0 0 89 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
    <path d="M85.0327 14.13H82.0327V4.25002H84.8327L84.9327 5.01002H84.9727C85.4527 4.71668 85.9394 4.49668 86.4327 4.35002C86.9394 4.19002 87.4127 4.11002 87.8527 4.11002H88.0127V7.01002C87.8927 6.98335 87.7594 6.97002 87.6127 6.97002C87.4794 6.95668 87.3061 6.95002 87.0927 6.95002C86.7061 6.95002 86.3394 6.97668 85.9927 7.03002C85.6461 7.07002 85.3261 7.13668 85.0327 7.23002V14.13Z" fill="#1B1B6F"/>
    <path d="M79.8323 11.21V13.59C79.5123 13.79 78.9723 13.9634 78.2123 14.11C77.4523 14.2567 76.6523 14.33 75.8123 14.33C74.2123 14.33 73.0589 13.93 72.3523 13.13C71.6456 12.3167 71.2923 11.0034 71.2923 9.19002C71.2923 7.41668 71.6656 6.11668 72.4123 5.29002C73.1589 4.46335 74.3389 4.05002 75.9523 4.05002C77.5256 4.05002 78.6323 4.36335 79.2723 4.99002C79.9256 5.60335 80.2523 6.69668 80.2523 8.27002C80.2523 8.69668 80.2456 9.03668 80.2323 9.29002C80.2189 9.54335 80.1923 9.83668 80.1523 10.17H74.3123C74.3256 10.7167 74.4723 11.0967 74.7523 11.31C75.0323 11.5234 75.5456 11.63 76.2923 11.63C76.9323 11.63 77.5856 11.59 78.2523 11.51C78.9189 11.4167 79.4456 11.3167 79.8323 11.21ZM75.9523 6.61002C75.3123 6.61002 74.8789 6.72335 74.6523 6.95002C74.4256 7.16335 74.3123 7.59002 74.3123 8.23002H77.5323V7.79002C77.5189 7.32335 77.4056 7.01002 77.1923 6.85002C76.9923 6.69002 76.5789 6.61002 75.9523 6.61002Z" fill="#1B1B6F"/>
    <path d="M63.432 17.17H60.432V4.25002H63.072L63.232 5.17002H63.272C63.632 4.79668 64.0586 4.51668 64.552 4.33002C65.0586 4.14335 65.632 4.05002 66.272 4.05002C67.5786 4.05002 68.5053 4.44335 69.052 5.23002C69.612 6.01668 69.892 7.33668 69.892 9.19002C69.892 10.99 69.5386 12.2967 68.832 13.11C68.1386 13.9234 67.0053 14.33 65.432 14.33C65.0453 14.33 64.6786 14.3034 64.332 14.25C63.9986 14.21 63.6986 14.15 63.432 14.07V17.17ZM66.892 9.19002C66.892 8.19002 66.7853 7.53668 66.572 7.23002C66.3586 6.91002 65.8986 6.75002 65.192 6.75002C64.8453 6.75002 64.5186 6.77668 64.212 6.83002C63.9186 6.88335 63.6586 6.96335 63.432 7.07002V11.29C63.632 11.3834 63.8586 11.45 64.112 11.49C64.3786 11.53 64.6786 11.55 65.012 11.55C65.8253 11.55 66.3386 11.41 66.552 11.13C66.7786 10.8367 66.892 10.19 66.892 9.19002Z" fill="#1B1B6F"/>
    <path d="M58.6702 9.19002C58.6702 11.0434 58.2969 12.3634 57.5502 13.15C56.8036 13.9367 55.5502 14.33 53.7902 14.33C52.0302 14.33 50.7769 13.9367 50.0302 13.15C49.2836 12.3634 48.9102 11.0434 48.9102 9.19002C48.9102 7.33668 49.2836 6.01668 50.0302 5.23002C50.7769 4.44335 52.0302 4.05002 53.7902 4.05002C55.5502 4.05002 56.8036 4.44335 57.5502 5.23002C58.2969 6.01668 58.6702 7.33668 58.6702 9.19002ZM51.9102 9.15002C51.9102 10.1634 52.0169 10.8167 52.2302 11.11C52.4569 11.4034 52.9769 11.55 53.7902 11.55C54.6036 11.55 55.1169 11.4034 55.3302 11.11C55.5569 10.8167 55.6702 10.1634 55.6702 9.15002C55.6702 8.13668 55.5569 7.48335 55.3302 7.19002C55.1169 6.89668 54.6036 6.75002 53.7902 6.75002C52.9769 6.75002 52.4569 6.89668 52.2302 7.19002C52.0169 7.48335 51.9102 8.13668 51.9102 9.15002Z" fill="#1B1B6F"/>
    <path d="M47.1437 0.830017V14.13H44.1437V0.830017H47.1437Z" fill="#ffffff"/>
    <path d="M41.9432 11.21V13.59C41.6232 13.79 41.0832 13.9634 40.3232 14.11C39.5632 14.2567 38.7632 14.33 37.9232 14.33C36.3232 14.33 35.1699 13.93 34.4632 13.13C33.7565 12.3167 33.4032 11.0034 33.4032 9.19002C33.4032 7.41668 33.7765 6.11668 34.5232 5.29002C35.2699 4.46335 36.4499 4.05002 38.0632 4.05002C39.6365 4.05002 40.7432 4.36335 41.3832 4.99002C42.0365 5.60335 42.3632 6.69668 42.3632 8.27002C42.3632 8.69668 42.3565 9.03668 42.3432 9.29002C42.3299 9.54335 42.3032 9.83668 42.2632 10.17H36.4232C36.4365 10.7167 36.5832 11.0967 36.8632 11.31C37.1432 11.5234 37.6565 11.63 38.4032 11.63C39.0432 11.63 39.6965 11.59 40.3632 11.51C41.0299 11.4167 41.5565 11.3167 41.9432 11.21ZM38.0632 6.61002C37.4232 6.61002 36.9899 6.72335 36.7632 6.95002C36.5365 7.16335 36.4232 7.59002 36.4232 8.23002H39.6432V7.79002C39.6299 7.32335 39.5165 7.01002 39.3032 6.85002C39.1032 6.69002 38.6899 6.61002 38.0632 6.61002Z" fill="#1B1B6F"/>
    <path d="M29.5618 4.25002H32.8218L29.4218 14.13H25.9818L22.5618 4.25002H25.8618L27.7218 11.41L29.5618 4.25002Z" fill="#ffffff"/>
    <path d="M21.4557 11.21V13.59C21.1357 13.79 20.5957 13.9634 19.8357 14.11C19.0757 14.2567 18.2757 14.33 17.4357 14.33C15.8357 14.33 14.6824 13.93 13.9757 13.13C13.269 12.3167 12.9157 11.0034 12.9157 9.19002C12.9157 7.41668 13.289 6.11668 14.0357 5.29002C14.7824 4.46335 15.9624 4.05002 17.5757 4.05002C19.149 4.05002 20.2557 4.36335 20.8957 4.99002C21.549 5.60335 21.8757 6.69668 21.8757 8.27002C21.8757 8.69668 21.869 9.03668 21.8557 9.29002C21.8424 9.54335 21.8157 9.83668 21.7757 10.17H15.9357C15.949 10.7167 16.0957 11.0967 16.3757 11.31C16.6557 11.5234 17.169 11.63 17.9157 11.63C18.5557 11.63 19.209 11.59 19.8757 11.51C20.5424 11.4167 21.069 11.3167 21.4557 11.21ZM17.5757 6.61002C16.9357 6.61002 16.5024 6.72335 16.2757 6.95002C16.049 7.16335 15.9357 7.59002 15.9357 8.23002H19.1557V7.79002C19.1424 7.32335 19.029 7.01002 18.8157 6.85002C18.6157 6.69002 18.2024 6.61002 17.5757 6.61002Z" fill="#1B1B6F"/>
    <path d="M5.32446 14.13H0.944458V1.59002H5.32446C6.60446 1.59002 7.61779 1.69668 8.36446 1.91002C9.12446 2.12335 9.73112 2.46335 10.1845 2.93002C10.6378 3.39668 10.9645 4.03002 11.1645 4.83002C11.3778 5.61668 11.4845 6.62335 11.4845 7.85002C11.4845 10.0234 10.9978 11.6167 10.0245 12.63C9.05113 13.63 7.48446 14.13 5.32446 14.13ZM7.78446 5.19002C7.59779 4.91002 7.32446 4.71002 6.96446 4.59002C6.60446 4.45668 6.05779 4.39002 5.32446 4.39002H4.04446V11.23H5.32446C6.04446 11.23 6.58446 11.17 6.94446 11.05C7.30446 10.93 7.58446 10.7367 7.78446 10.47C7.95779 10.2167 8.08446 9.88335 8.16446 9.47002C8.24446 9.04335 8.28446 8.50335 8.28446 7.85002C8.28446 7.18335 8.24446 6.64335 8.16446 6.23002C8.08446 5.80335 7.95779 5.45668 7.78446 5.19002Z" fill="#1B1B6F"/>
  </svg>
</ng-template>
