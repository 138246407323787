import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class FirstPageLoadService {
  firstPageLoad = true;
  previousURL = '';
  alwaysLoadPages = ['dashboard/', 'account/'];

  constructor(public router: Router) {
    this.previousURL = this.router.url;
  }

  /**
   * This function controls if the loader is displayed based on a set of parameters.
   * This service is only used in the base app.component and must exist because of scully
   * On initial page load, we don't want to show the loader because we want the fastest contextual paint
   * Some pages route to themselves, and for this we must keep track of the last routed URL
   * @param pageRoute
   * @returns
   */
  loadContent(pageRoute: string) {
    // This block will need to be reversed at some point to only include staging
    if (environment.envName === 'development') {
      if (this.firstPageLoad) {
        // There are some routes that we will always want the loader to show, and that is defined in the array above
        if (this.alwaysLoadPages.some((route) => pageRoute.includes(route))) {
          this.firstPageLoad = false;
          return true;
        } else {
          this.firstPageLoad = false;
          this.previousURL = pageRoute;
          return false;
        }
      } else {
        if (this.previousURL === pageRoute) {
          this.previousURL = pageRoute;
          return false;
        } else {
          this.previousURL = pageRoute;
          return true;
        }
      }
    } else {
      if (this.previousURL === pageRoute) {
        this.previousURL = pageRoute;
        return false;
      } else {
        this.previousURL = pageRoute;
        return true;
      }
    }
  }
}
