import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wp-form-question',
  templateUrl: './wp-form-question.component.html',
  styleUrls: ['./wp-form-question.component.scss']
})
export class WpFormQuestionComponent implements OnInit {
  @Input() question: any;
  @Input() form: any;
  get isValid() { return this.form.controls[this.question.key].valid; }
  get isDirty() { return this.form.controls[this.question.key].dirty; }
  get isTouched() { return this.form.controls[this.question.key].touched; }
  get errors() { return this.form.controls[this.question.key].errors; }

  // -------------- Tool Tip Variables --------------
  _tooltipClosed = true;
  _tooltipLocation =  {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  };
  _svgChar = {
    x: 'left',
    y: 'top',
    d: 'M0,0 L50,0 L50,50 L0,50 L0,0 Z',
    viewBox: '0 0 0 0',
  };

  constructor() { }

  ngOnInit() {
  }

  // -------------- Tool Tip Functions --------------
  showTooltip($event) {
    // this._tooltipClosed = false;

    let location = $event.target.getBoundingClientRect();

    // this._tooltipLocation = $event.target.getBoundingClientRect() - 50
    // this._tooltipLocation


    this._tooltipLocation.bottom = location.bottom
    this._tooltipLocation.height = location.height
    this._tooltipLocation.left = location.left
    this._tooltipLocation.right = location.right
    this._tooltipLocation.top = location.top
    this._tooltipLocation.width = location.width
    this._tooltipLocation.x = location.x-355
    this._tooltipLocation.y = location.y+120

  }
  clearTooltip() {
    this._tooltipClosed = true;
  }
}
