import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from 'app/services/menu.service';
import { MetaService } from 'app/services/meta.service';

@Component({
  selector: 'docheader-secondary-nav',
  templateUrl: './docheader-secondary-nav.component.html',
})
export class DocheaderSecondaryNavComponent implements OnInit {
  pageRoute: string;
  navigationTabs: any;
  canSubscribe = false;
  metadataObj = null;

  constructor(private router: Router, private menuService: MenuService, private metaService: MetaService) {}

  ngOnInit(): void {
    this.pageRoute = this.router.url.substring(1);
    this.metaService.metadata.subscribe((metadata) => {
      this.metadataObj = metadata;
      if (metadata && metadata['secondary-nav']) {
        // added a check to identify between urls array and menu fine name
        if (Array.isArray(metadata['secondary-nav'])) {
          this.navigationTabs = metadata['secondary-nav'];
          // extra logic to keep api-spec tab active in case of version appended to url
          this.navigationTabs.forEach((tab) => {
            if (tab.path.indexOf('api-specification') > -1 && this.router.url.indexOf(tab.path) > -1) {
              tab.active = true;
            }
          });
        } else {
          this.menuService.getMenu(metadata['secondary-nav']).subscribe((menus: any) => {
            if (menus && menus.Items && menus.Items.length > 0 && menus.Items[0].items) {
              this.navigationTabs = menus.Items[0].items;
            }
          });
        }
      }
      this.canSubscribe = metadata && metadata['subscription-group'] ? true : false;
    });
  }

  getActiveClass(navItem) {
    if(navItem.title === 'Documentation'){
      const path = (navItem.path.charAt(0) === '/') ? navItem.path : (('/')+navItem.path);
      return path == this.router.routerState.snapshot.url.split('#')[0];
    } 
  }
}
