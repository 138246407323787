<div class="d-md-flex workflowTask -container">
  <div class="workflowTask -card -step">
    <div class="d-md-flex align-items-center">
      <icon
        [icon]="iconProperties.iconPath"
        [ngClass]="iconProperties.className">
      </icon>
      <h5>
        Step {{ step }}
      </h5>
    </div>
  </div>

  <div class="workflowTask -card -content flex-grow-1">
    <h5>
      {{ title }}
    </h5>
    <p class="workflowTask -description -fontSmall" [innerHTML]="description">
      {{ description }}
    </p>
  </div>

  <div class="workflowTask -card -links">
    <div #contentWrapper>
      <h5 *ngIf="contentWrapper.children.length >= 1">Helpful Links:</h5>
      <ng-content></ng-content>
    </div>
  </div>
</div>

