<ng-container *ngIf="meta">
  <small-screen-modal class="-overlay-container" [ngClass]="{'-overlay-slide-in': sidebarShow}" (click)="sidebarEventHandler()"></small-screen-modal>
  <span class="sidebar-slider" [class.sidebar-slide-in]="sidebarShow" [ngClass]="meta.template === globals.TEMPLATE_TYPES.API_OVERVIEW ? '-no-sec-nav-offset' : isSecNav ? (meta['subscription-group']) ? '-sec-nav-with-release-notes-offset' : '-sec-nav-offset' : '-no-sec-nav-offset'">
    <nav-vertical-updated [ngClass]="{'-doc-page-nav': meta.template === 'doc-page' , '-hide-sec-nav': !isSecNav}" slideNav="slideNav"></nav-vertical-updated>
    <div class="sidebar-opener" (click)="sidebarEventHandler()">
    </div>
  </span>
</ng-container>
<ng-container *ngIf="!meta">
  <small-screen-modal class="-overlay-container" [ngClass]="{'-overlay-slide-in': sidebarShow}" (click)="sidebarEventHandler()"></small-screen-modal>
  <span class="sidebar-slider -no-sec-nav-offset" [class.sidebar-slide-in]="sidebarShow">
    <nav-vertical-updated [allowLoaderControl]="false" slideNav="slideNav" [ngClass]="{'-account': !meta}"></nav-vertical-updated>
    <div class="sidebar-opener" (click)="sidebarEventHandler()"></div>
  </span>
</ng-container>




