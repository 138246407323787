import { MetaService } from 'app/services/meta.service';
import { Component, OnInit, Input } from '@angular/core';
import { PageService } from '../../../services/page.service';
import { Breadcrumb } from '../../../types/breadcrumb';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  @Input() items: Breadcrumb[];
  pageRoute: string;
  urlSubscription;
  breadCrumbs: Breadcrumb[];
  paths: string[];
  currentPageTitle: string;
  showBreadcrumbs = true;
  isApiSpec = false;
  iconColor = '-colorAccentLightBlue';
  staticIgnorePaths = ['dashboard'];

  constructor(
    private pageService: PageService,
    private router: Router,
    public store: Store<any>,
    private metaService: MetaService
  ) {}

  ngOnInit() {
    // Use router instead of route because router takes into account module paths
    // Remove first string character because it is a '/' and it breaks the breadcrumbs parser downstream
    this.pageRoute = this.router.url.substring(1);
    this.currentPageTitle = this.metaService.getTitle().split('|')[0];

    // For Home page
    if (this.pageRoute === '') {
      this.items = [new Breadcrumb('', '')];
      this.showBreadcrumbs = false;
    } else {
      this.paths = this.pageRoute.split('/').map((x: string) => `/${x}`);
    }

    // To show versioning
    if (this.paths) {
      if (this.paths.includes('/api-specification')) {
        this.isApiSpec = true;
      }
    }

    this.buildBreadCrumbs();
  }

  buildBreadCrumbs() {
    // Create path combinations for all parent paths
    for (const x in this.paths) {
      if (x !== '0') {
        this.paths[x] = this.paths[parseInt(x, 10) - 1] + this.paths[x];
      }
    }

    // Get last element (actual path) and remove from array so call is not made again
    if (this.paths) {
      const lastElementPath = this.paths[this.paths.length - 1];
      this.paths.pop();

      // Set first and last path; home and current page
      this.breadCrumbs = new Array(this.paths.length + 2).fill(new Breadcrumb('', ''));
      this.breadCrumbs[0] = new Breadcrumb('Home', '/');
      this.breadCrumbs[this.breadCrumbs.length - 1] = new Breadcrumb(this.currentPageTitle, lastElementPath);

      // Counter is to display breadcrumbs once they are finished being processed
      let counter = 2;
      if (this.breadCrumbs.length === 2) {
        this.showBreadcrumbs = true;
      }

      this.paths.forEach((path, i) => {
        if (this.staticIgnorePaths.some((staticPath) => path.includes(staticPath))) {
          this.breadCrumbs[i + 1] = new Breadcrumb(path.replace('/', '')[0].toUpperCase() + path.slice(2), path);
        } else {
          this.pageService.getPage(path).subscribe((page) => {
            counter++;
            if (counter === this.breadCrumbs.length) {
              this.showBreadcrumbs = true;
            }
            if (page.Item.metadata.ignore) {
              this.breadCrumbs[i + 1] = new Breadcrumb(page.Item.title, page.Item.metadata.route);
            } else {
              this.breadCrumbs[i + 1] = new Breadcrumb(page.Item.title, path);
            }
          });
        }
      });
      this.items = this.breadCrumbs;
    }
  }
  // eslint-disable-next-line
  ngOnDestroy() {
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
    }
  }

  mouseEnter(index: number) {
    if (index === 0) {
      this.iconColor = '-colorPrimaryGreen';
    }
  }

  mouseLeave(index: number) {
    if (index === 0) {
      this.iconColor = '-colorAccentLightBlue';
    }
  }
}
