import { Component, OnInit, Input, ChangeDetectorRef, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DocPageNotificationService } from 'app/services/docpage-notification.service';

@Component({
  selector: 'mastheadcta',
  templateUrl: './masthead-cta.component.html',
  styleUrls: ['./masthead-cta.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MastheadCTAComponent implements OnInit, AfterViewInit {
  @Input() hidebreadcrumbs = false;
  @Input() offset = null;
  @Input() alignment: string;
  contentClass = '';
  notification = null;

  constructor(
    private changeRef: ChangeDetectorRef,
    public store: Store<any>,
    private notificationService: DocPageNotificationService
  ) {}

  ngOnInit() {
    if (this.alignment === 'center') {
      this.contentClass = 'mastheadcta -content d-flex align-items-center';
    } else {
      this.contentClass = 'mastheadcta -content d-flex align-items-start';
    }

    this.notificationService.notification.subscribe((notification: any) => {
      this.notification = notification;
    });
  }

  ngAfterViewInit() {
    this.changeRef.detectChanges();
  }
}
