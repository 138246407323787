import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocPageNotificationService {
  notification: Subject<any> = new Subject();

  constructor() {}

  sendNotification(notification: any): void {
    this.notification.next(notification);
  }
}
