<div class="workflow-card -card">
  <div class="workflow-card -status d-flex align-items-stretch">
    <div *ngIf="!isApiPage">
      <icon
        [icon]="iconProperties.iconPath"
        [ngClass]="iconProperties.className">
      </icon>
    </div>
    <div [ngClass]="_childrenCollapsedState.closed ? '-card-number-collapsed' : '-card-number-expanded'" *ngIf="isApiPage">
      <span class="-card-no">{{card}}</span>
    </div>
    <div
      *ngIf="order === 'last' ? false : true"
      [ngClass]="(isApiPage && _childrenCollapsedState.closed && !card) ? 'workflow-card -spacer-collapsed' : 'workflow-card -spacer'">
    </div>
  </div>
  <div [ngClass]="bottomCardPadding ? 'workflow-card -content -bottom-padding' : 'workflow-card -content'" class="workflow-card -content">
    <span class="workflow-card -content-title">
      {{ title }}
      &nbsp;
      <span class="workflow-card -content-subtitle" *ngIf="tasksComplete > -1;else basicTask">
        ({{ tasksComplete }} of {{ taskCount }} complete)
      </span>
      <ng-template #basicTask>
        <span  class="-fontXSmall" >
          {{ taskCount }}
        </span>
      </ng-template>
    </span>
    <p class="-fontSmall workflow-card" [ngClass]="isApiPage ? '-content-description-width' : '-content-description'">
      {{ description }}
    </p>
    
    <div [hidden]="nocontent">
      <div
        #childrenContainer
        class="workflow-card -childrenContainer"
        [@_closed]="_childrenCollapsedState.closed">
        <ng-content></ng-content>
      </div>
   </div>
   <link-icon
      iconclass="-sm -colorPrimaryGreen"
      class="workflow-card -link-icon -toggle"
      (click)="changeExpandState()"
      [icon]="_childrenCollapsedState.iconPath">
      {{ _childrenCollapsedState.label }}
    </link-icon>
  </div>
</div>