import { LoaderService } from './../../../services/loader.service';
import { MetaService } from 'app/services/meta.service';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MenuService } from '../../../services/menu.service';
import { Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  AUTO_STYLE,
  // ...
} from '@angular/animations';
@Component({
  selector: 'nav-vertical',
  templateUrl: './nav-vertical.component.html',
  styleUrls: ['./nav-vertical.component.scss'],
  animations: [
    trigger('_closed', [
      state(
        'false',
        style({
          height: AUTO_STYLE,
        })
      ),
      state(
        'true',
        style({
          height: 0,
        })
      ),
      transition('false <=> true', [animate('125ms')]),
    ]),
  ],
})
export class NavVerticalComponent implements OnInit, OnDestroy {
  @Input() createdNavItems: any = null;
  @Input() metadata: any = null;
  @Input() allowLoaderControl = true;
  navItems: any[];
  _closed = false;
  _closedStates: any = {
    p: [],
    c: [],
  };
  subscription = new Subscription();
  currentMenu = '';

  toggleChild(level: string, id: number) {
    if (this._closedStates[level][id]) {
      this._closedStates[level][id]['state'] = !this._closedStates[level][id]['state'];

      let newIcon = '';

      switch (this._closedStates[level][id]['icon']) {
        case 'dropdown':
          newIcon = 'dropup';
          break;
        case 'dropup':
          newIcon = 'dropdown';
          break;
        case 'subtract':
          newIcon = 'plus';
          break;
        case 'plus':
          newIcon = 'subtract';
          break;
      }

      this._closedStates[level][id]['icon'] = newIcon;
      // this._closed = !this._closed;
    }
  }

  constructor(
    private menuService: MenuService,
    private router: Router,
    private metaService: MetaService,
    private loaderService: LoaderService
  ) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  /*
    There should only ever be one metadata data object per page.
      Static pages have no metadata, and until that changes this functionality will work.
    Currently, the only page with multiple vertical navs on it is the styleguide.
      The first if block below supports multiple navs by passing the metadata directly.
    The loader can be controlled by this component because so far this is the last piece of content pages
      that loads.
  */
  ngOnInit() {
    if (this.metadata) {
      if (this.metadata['vertical-nav']) {
        this.getMenu(this.metadata['vertical-nav']);
      }
    } else {
      this.subscription.add(
        this.metaService.metadata.subscribe((value) => {
          this.getMenu(value['vertical-nav']);
        })
      );
    }
  }

  getMenu(menuName: string) {
    if (menuName && menuName !== this.currentMenu) {
      this.subscription.add(
        this.menuService.getMenu(menuName).subscribe((menu) => {
          if (menu.Items.length > 0) {
            this.navSetup(menu.Items[0]);
            this.currentMenu = menuName;
          }

          this.controlLoader();
        })
      );
    } else {
      this.controlLoader();
    }
  }

  controlLoader() {
    if (this.allowLoaderControl) {
      this.loaderService.loadingContent.next(false);
    }
  }

  navSetup(menu: any) {
    if (menu.items && menu.items.length > 0) {
      this.navItems = menu.items;

      for (const item in this.navItems) {
        if (this.navItems[item]['children']) {
          if (this.navItems[item]['showOnLoad']) {
            this._closedStates['p'][item] = {
              state: false,
              icon: 'dropup',
            };
          } else {
            this._closedStates['p'][item] = {
              state: true,

              icon: 'dropdown',
            };
          }

          for (const child in this.navItems[item]['children']) {
            if (this.navItems[item]['children'][child]) {
              if (this.navItems[item]['children'][child].path.includes('#')) {
                if (!this.navItems[item]['children'][child].path.includes('/')) {
                  this.navItems[item]['children'][child].path =
                  this.router.url.split('#')[0] + this.navItems[item]['children'][child].path;
                }
              }
            }

            if (this.navItems[item]['children'][child]['children']) {
              if (this.navItems[item]['children'][child]['showOnLoad']) {
                this._closedStates['c'][child] = {
                  state: false,
                  icon: 'subtract',
                };
              } else {
                this._closedStates['c'][child] = {
                  state: true,
                  icon: 'plus',
                };
              }
            }
          }
        }
      }
    }
  }
}
