import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private apiService: ApiService) {}

  getEncryptedData(): Observable<string> {
    return new Observable((observer) => {
      this.apiService.get('/api/Chat/Token').subscribe((token: any) => {
        observer.next((token && token.token) ? token.token : '');
        observer.complete();
      }, () => {
        observer.next('');
        observer.complete();
      });
    });
  }
}
