import { Component } from '@angular/core';

@Component({
  selector: 'app-breakpoint',
  templateUrl: './breakpoint.component.html',
  styleUrls: ['./breakpoint.component.scss'],
})
export class BreakpointComponent {
  constructor() {}

}
