import { Toggle, ToggleAction } from '../../types/toggle';

const defaultState: Toggle[] = [];

// Actions
export const TOGGLE = 'TOGGLE';

// Action creators
export const toggle = (name: string, active: any = undefined) => ({
  type: TOGGLE,
  name,
  active,
});

export default (state: Toggle[] = defaultState, action: ToggleAction) => {
  if (action.type === TOGGLE) {
    let active = undefined;

    if (!state.filter(toggle => toggle.name === action.name).length) {
      state.push({
        name: action.name,
        active,
      });
    }

    state = state.map(toggle => {
      if (toggle.name === action.name) {
        if (typeof action.active === 'boolean') {
          active = action.active;
        } else {
          active = !toggle.active;
        }

        return {
          ...toggle,
          active,
        };
      }

      return toggle;
    });
  }

  return state;
};
