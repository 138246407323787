import { Component, ElementRef, ViewChild, Input, AfterViewInit} from '@angular/core';

@Component({
  selector: 'testimonial',
  templateUrl: './testimonial.component.html',
})
export class TestimonialComponent implements AfterViewInit {
  @Input() companies;

  @ViewChild('testimonial') testimonialElement: ElementRef<HTMLElement>;
  testimonialItems: NodeListOf<HTMLElement>;
  testimonialsLoaded = false;
  lastIndex = 0; 

  constructor() {}

  ngAfterViewInit(): void {
    this.testimonialsLoaded = true;
    this.testimonialItems = this.testimonialElement.nativeElement.querySelectorAll('testimonial-item');
    if (!this.companies || this.companies.length === 0) {
      this.testimonialItems[0].classList.add('-no-controls');
      this.testimonialItems[0].classList.add('-visible');
      this.lastIndex = 0;
    } 
  }

  selectTestimonial(currentIndex: number): void {
    this.testimonialItems[this.lastIndex].classList.remove('-visible');
    this.testimonialItems[currentIndex].classList.add('-visible');
    this.lastIndex = currentIndex;
  }
}
